export interface StatesResponse {
  data: Array<State>;
}

export interface State {
  abbreviation: string;
  communityPropertyState: boolean;
  fips: string;
  name: string;
  country: Country;
}

export interface StateTaxDeadline {
  state: string;
  taxYear: number;
  filingDeadline: string;
  promptText: string;
}

export interface StateTaxDeadlineRequest  { 
  data?: Array<StateTaxDeadline>;
  errorMessage?: any;
}

// Convert locale codes to our API's country enum
export enum Country {
  'en-US' = 'USA',
  'en-CA' = 'CANADA',
  'en-IE' = 'IRELAND',
  'sv' = 'SWEDEN',
  'en-GB' = 'UK',
  'en-CH' = 'SWITZERLAND',
  'fr'='FRANCE',
  'nl'='NETHERLANDS',
  'de' ='GERMANY',
  'en-WI'='WEST_INDIES',
  'il'='ISRAEL',
  'cn'='CHINA',
  'vi'='VIETNAM',
  'ph'='PHILIPPINES',
}
