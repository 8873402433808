import React from 'react';
import {
  useLocation,
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { useTheme, lighten } from '@mui/material/styles';

import { Box, Divider } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Dashboard from '@mui/icons-material/Dashboard';
import SwapHoriz from '@mui/icons-material/SwapHoriz';
import FileUploadIcon from '@mui/icons-material/UploadFile';
import MergeIcon from '@mui/icons-material/Merge';

import { useUser } from '../auth/useUser';
import { UserRole } from '../api/UserApi.d';
import OnboardingIcon from '../icons/Onboarding';
import BranchSearchIcon from '../icons/BranchSearch';
import AccountSearchIcon from '../icons/AccountSearch';
import UserSearchIcon from '../icons/UserSearch';
import OrganizationSettingsIcon from '../icons/OrganizationSettings';
import RecurringTransactionIcon from '../icons/RecurringTransactions';
import TaxReportingIcon from '../icons/TaxReporting';
import BeneficiaryClaimsIcon from '../icons/BeneficiaryClaims';
import SiteSettingsIcon from '../icons/SiteSettings';
import { Solution } from '../api/OrganizationApi.d';
import { useGlobalContext } from '../auth/useGlobalContext';

interface ListItemLinkProps {
  dataQa?: string;
  icon?: React.ReactElement;
  primary: string;
  to: string;
  roles?: Array<UserRole>;
  solutions?: Array<Solution>;
}

function ListItemLink(props: ListItemLinkProps) {
  const { icon = '', primary, to, solutions, roles, dataQa } = props;
  const theme = useTheme();
  const location = useLocation();
  const { pathname } = location;

  const { organization } = useGlobalContext();
  const isActiveLink = pathname.split('/')[1] === to.split('/')[1];
  const { user } = useUser();
  const { roles: userRoles } = user;

  // Does the user have any of the roles in the permitted roles passed in?
  const hasMatchingRoles =
    roles && userRoles
      ? userRoles.some((role) => roles.includes(role as UserRole))
      : true;

  // Does the org have a solution tier that is allowed?
  const hasMatchingSolutions =
    solutions && organization.solution
      ? solutions.includes(organization.solution)
      : true;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  const classes = {
    listItem: {
      borderLeft: '4px solid transparent',
      color: 'white',
      background: `linear-gradient(${lighten(
        theme.palette.primary.main,
        0.1
      )} 40%, ${theme.palette.primary.main})`,
      borderBottom: `2px solid ${theme.palette.primary.dark}`,
    },
    listItemActive: {
      borderLeft: `4px solid ${theme.palette.text.secondary}`,
      background: theme.palette.primary.dark,
      color: theme.palette.grey[500],
    },
    listItemIcon: {
      color: theme.palette.text.secondary,
    },
  };

  return hasMatchingRoles && hasMatchingSolutions ? (
    <li>
      <ListItem
        data-qa={dataQa}
        sx={isActiveLink ? classes.listItemActive : classes.listItem}
        button
        component={renderLink}
      >
        {icon ? (
          <ListItemIcon sx={classes.listItemIcon}>{icon}</ListItemIcon>
        ) : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  ) : null;
}

const LeftNav = () => {
  const theme = useTheme();
  const { user } = useUser();
  const { organizationId } = user;
  const classes = {
    root: {
      [theme.breakpoints.up('md')]: {
        position: 'fixed',
        top: '64px',
        left: 0,
        height: '100%',
        minHeight: '100vh',
        background: `linear-gradient(${lighten(
          theme.palette.primary.main,
          0.1
        )} 25%, ${theme.palette.primary.main})`,
      },
    },
    list: {
      [theme.breakpoints.up('md')]: {
        position: 'sticky',
        top: '0',
      },
    },
    divider: {
      backgroundColor: theme.palette.primary.light,
    },
  };

  return (
    <Box sx={classes.root}>
      <List sx={classes.list} disablePadding>
        {organizationId && (
          <>
            <ListItemLink
              dataQa="dashboard_leftNav"
              to="/"
              primary="Dashboard"
              icon={<Dashboard />}
            />
            <ListItemLink
              dataQa="transactions_leftNav"
              roles={[UserRole.orgTransactions, UserRole.orgTransactionsAdmin]}
              to="/transactions"
              primary="Transactions"
              icon={<SwapHoriz />}
            />
            <ListItemLink
              dataQa="accountOwner_leftNav"
              roles={[UserRole.orgTransactions, UserRole.orgTransactionsAdmin]}
              to="/accountOwner"
              primary="Account Search"
              icon={<AccountSearchIcon />}
            />
            <ListItemLink
              dataQa="recurringDistributions_leftNav"
              solutions={[Solution.green, Solution.black]}
              roles={[UserRole.orgRecurringDistributions]}
              to="/recurringDistributions"
              primary="Recurring Distributions"
              icon={<RecurringTransactionIcon />}
            />
            <ListItemLink
              dataQa="beneficiaryClaims_leftNav"
              solutions={[Solution.green, Solution.black]}
              roles={[UserRole.orgBeneficiaryClaims]}
              to="/beneficiaryClaims"
              primary="Beneficiary Claims"
              icon={<BeneficiaryClaimsIcon />}
            />
            <ListItemLink
              dataQa="taxReporting_leftNav"
              solutions={[Solution.black]}
              roles={[UserRole.orgTaxReporting]}
              to="/taxReporting"
              primary="Tax Reporting"
              icon={<TaxReportingIcon />}
            />
            <ListItemLink
              dataQa="users_leftNav"
              roles={[UserRole.orgUserAdmin]}
              to="/users"
              primary="Users"
              icon={<UserSearchIcon />}
            />
            <ListItemLink
              dataQa="dataImport_leftNav"
              roles={[UserRole.orgDataProcessor]}
              to="/dataImport"
              primary="Data Import"
              icon={<FileUploadIcon />}
            />
            {user.organizationId && (
              <ListItemLink
                dataQa="orgSettings_leftNav"
                roles={[UserRole.orgSiteAdmin]}
                to="/orgSettings"
                primary="Organization Settings"
                icon={<OrganizationSettingsIcon />}
              />
            )}
          </>
        )}
        <Box p={2}>
          <Divider sx={classes.divider} />
        </Box>
        <ListItemLink
          key="searchOrg"
          dataQa="searchOrg"
          roles={[UserRole.multi]}
          to="/org"
          primary="Search Organizations"
          icon={<BranchSearchIcon />}
        />
        <ListItemLink
          dataQa="beneficiaryClaimsDashboard_leftNav"
          roles={[UserRole.multi]}
          to="/beneficiaryClaimsDashboard"
          primary="Beneficiary Claims Dashboard"
          icon={<BeneficiaryClaimsIcon />}
        />
        <ListItemLink
          dataQa="taxReportingDashboard_leftNav"
          roles={[UserRole.multi]}
          to="/taxReportingDashboard"
          primary="Tax Reporting Dashboard"
          icon={<TaxReportingIcon />}
        />
        <ListItemLink
          dataQa="onboarding_leftNav"
          roles={[UserRole.admin]}
          to="/onboarding"
          primary="Onboarding"
          icon={<OnboardingIcon />}
        />
        <ListItemLink
          dataQa="merger_leftNav"
          roles={[UserRole.admin]}
          to="/merger"
          primary="Merger"
          icon={<MergeIcon />}
        />
        <ListItemLink
          dataQa="siteSettings_leftNav"
          roles={[UserRole.admin]}
          to="/siteSettings"
          primary="Site Settings"
          icon={<SiteSettingsIcon />}
        />
      </List>
    </Box>
  );
};

export default LeftNav;
