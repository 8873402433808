import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { Grid, IconButton, Typography, Box } from '@mui/material';
import PendingIcon from '@mui/icons-material/Pending';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';

import {
  globalPaginationOptions,
  rmdNotificationTypeLabels,
  withholdingNotificationTypeLabels,
} from '../../app.constants';
import { useUser } from '../../auth/useUser';
import {
  useGlobalContext,
  usePaginationContext,
} from '../../auth/useGlobalContext';
import { Account } from '../../api/AccountApi.d';
import SiraNoRowsOverlay from '../SiraNoRowsOverlay';
import {
  deleteAccountWithholdingNotifications,
  getAccountWithholdingNotifications,
  getWithholdingNotificationDocument,
} from '../../api/WithholdingNotificationApi';
import {
  WithholdingNotification,
  WithholdingNotificationResponse,
  WithholdingNotificationStatus,
  WithholdingNotificationType,
} from '../../api/WithholdingNotificationApi.d';
import { AccountOwner } from '../../api/AccountOwnerApi.d';
import { dateValueFormatter } from '../../utils/DataGrid.utils';
import {
  RmdNotification,
  RmdNotificationResponse,
  RmdNotificationStatus,
  RmdNotificationType,
} from '../../api/RmdNotificationApi.d';
import {
  deleteAccountRmdNotifications,
  getAccountRmdNotifications,
  getRmdNotificationDocument,
} from '../../api/RmdNotificationApi';
import { createFileBlobFromBase64 } from '../../utils/App.utils';
import {
  AmendmentNotification,
  AmendmentNotificationStatus,
} from '../../api/AmendmentNotificationApi.d';
import {
  deleteAccountAmendmentNotifications,
  getAccountAmendmentNotifications,
  getAmendmentNotificationDocument,
} from '../../api/AmendmentNotificationApi';
import { UserRole } from '../../api/UserApi.d';
import { errorMessages } from '../../utils/errorhandling.utils';

enum NotificationType {
  rmd = 'RMD_NOTIFICATION',
  withholding = 'WITHHOLDING_NOTIFICATION',
  amendment = 'AMENDMENT_NOTIFICATION',
}

interface AccountOwnerAccountWithholdingNoticesProps {
  account: Account;
}

interface WithholdingNotificationRow extends WithholdingNotification {
  label: string;
  fullName: string;
  taxpayerIdNumber: string;
  account: Account;
  accountOwner: AccountOwner;
  type: WithholdingNotificationType;
  status: WithholdingNotificationStatus;
}

interface RmdNotificationRow extends RmdNotification {
  label: string;
  fullName: string;
  taxpayerIdNumber: string;
  account: Account;
  accountOwner: AccountOwner;
  type: RmdNotificationType;
  status: RmdNotificationStatus;
  notificationType: NotificationType;
  notificationId: string;
}

interface AmendmentNotificationRow extends AmendmentNotification {
  label: string;
  status: AmendmentNotificationStatus;
  notificationType: NotificationType;
  notificationId: string;
  type: string;
}

export const TAX_YEAR_FILTER_SCHEMA = {
  taxYearFilters: yup.array(yup.number()).label('Filters'),
};

// Flatten and map out the withholding notifications for display in the data grid
function WithholdingNotificationRowsFactory(
  notifications: Array<any>
): Array<any> {
  return notifications.map((result) => ({
    ...result,
    label: 'Withholding Notification',
    type: result.withholdingNotificationType,
    status: result.withholdingNotificationStatus,
    notificationType: NotificationType.withholding,
    notificationId: result.withholdingNotificationId,
    notificationSent: result.withholdingNotificationDateSent,
  }));
}

function RmdNotificationRowsFactory(
  notifications: Array<RmdNotificationResponse>
): Array<RmdNotificationRow> {
  return notifications.map((result) => ({
    ...result.rmdNotification,
    label: 'RMD Notification',
    accountOwner: result.accountOwner,
    account: result.account,
    fullName: result.accountOwner.fullName,
    taxpayerIdNumber: result.accountOwner.taxpayerIdNumber,
    type: result.rmdNotification.rmdNotificationType,
    status: result.rmdNotification.rmdNotificationStatus,
    notificationType: NotificationType.rmd,
    notificationId: result.rmdNotification.rmdNotificationId,
  }));
}

function AmendmentNotificationRowsFactory(
  notifications: Array<AmendmentNotification>
): Array<AmendmentNotificationRow> {
  return notifications.map((result) => ({
    ...result,
    label: 'Amendment Notification',
    notificationType: NotificationType.amendment,
    status: result.amendmentNotificationStatus,
    notificationId: result.amendmentNotificationId,
    type: result.description,
  }));
}

function AccountOwnerAccountWithholdingNotices(
  props: AccountOwnerAccountWithholdingNoticesProps
) {
  let isMounted = true;
  const { addGlobalMessage } = useGlobalContext();
  const { account } = props;
  const { accountId, accountOwnerId } = account;
  const { user } = useUser();
  const [withholdingNotifications, setWithholdingNotifications] = useState(
    [] as Array<WithholdingNotificationRow>
  );
  const { setGlobalPageSize } = usePaginationContext();

  const [rmdNotifications, setRmdNotifications] = useState(
    [] as Array<RmdNotificationRow>
  );
  const [amendmentNotifications, setAmendmentNotifications] = useState(
    [] as Array<AmendmentNotificationRow>
  );
  const [userRole, setUserRole] = useState(false as boolean);
  const [isAccountOwner, setIsAccountOwner] = useState(false as boolean);

  // Fetch all withholding notifications for the current account
  async function getAndSetAccountWithholdingNotifications(): Promise<void> {
    await getAccountWithholdingNotifications(
      user.organizationId,
      accountOwnerId,
      accountId,
      user.token,
      user
    )
      .then(({ data = [] }) => {
        if (isMounted) {
          setWithholdingNotifications(WithholdingNotificationRowsFactory(data));
        }
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) ||
            'Error fetching account withholding notifications'
        );
      });
  }

  // Fetch all RMD notifications for the current account
  async function getAndSetAccountRmdNotifications(): Promise<void> {
    await getAccountRmdNotifications(
      user.organizationId,
      accountOwnerId,
      accountId,
      user.token,
      user
    )
      .then(({ data = [] }) => {
        if (isMounted) {
          setRmdNotifications(RmdNotificationRowsFactory(data));
        }
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Error fetching account RMD notifications'
        );
      });
  }

  // Fetch all amendment notifications for the current account
  async function getAndSetAccountAmendmentNotifications(): Promise<void> {
    await getAccountAmendmentNotifications(
      user.organizationId,
      accountOwnerId,
      accountId,
      user.token,
      user
    )
      .then(({ data = [] }) => {
        if (isMounted) {
          setAmendmentNotifications(AmendmentNotificationRowsFactory(data));
        }
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Error fetching account amendment notifications'
        );
      });
  }

  async function deleteWithholdingNotification(
    organizationId,
    withholdingNotificationId,
    token
  ): Promise<void> {
    await deleteAccountWithholdingNotifications(
      organizationId,
      withholdingNotificationId,
      accountOwnerId,
      accountId,
      token,
      user
    )
      .then(() => {
        getAndSetAccountWithholdingNotifications();
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) ||
            'Error deleting account withholding notification'
        );
      });
  }

  async function deleteRmdNotification(
    organizationId,
    rmdNotificationId,
    token
  ): Promise<void> {
    await deleteAccountRmdNotifications(
      organizationId,
      rmdNotificationId,
      accountOwnerId,
      accountId,
      token,
      user
    )
      .then(() => {
        getAndSetAccountRmdNotifications();
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Error deleting account RMD notification'
        );
      });
  }

  async function deleteAmendmentNotification(
    organizationId,
    amendmentNotificationId,
    token
  ): Promise<void> {
    await deleteAccountAmendmentNotifications(
      organizationId,
      amendmentNotificationId,
      accountOwnerId,
      accountId,
      token,
      user
    )
      .then(() => {
        getAndSetAccountAmendmentNotifications();
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Error deleting account amendment notification'
        );
      });
  }
  // Delete notifications for the current account
  const deleteNotifications = (notification) => {
    switch (notification.label) {
      case 'Withholding Notification':
        deleteWithholdingNotification(
          user.organizationId,
          notification.withholdingNotificationId,
          user.token
        );
        break;
      case 'RMD Notification':
        deleteRmdNotification(
          user.organizationId,
          notification.notificationId,
          user.token
        );
        break;
      case 'Amendment Notification':
        deleteAmendmentNotification(
          user.organizationId,
          notification.notificationId,
          user.token
        );
        break;
      default:
        break;
    }
  };

  async function openPdf({
    notificationType = '' as NotificationType,
    notificationId = '',
  }) {
    // Map out document endpoint based on the notficationType
    const documentFetch = {
      [NotificationType.rmd]: getRmdNotificationDocument,
      [NotificationType.withholding]: getWithholdingNotificationDocument,
      [NotificationType.amendment]: getAmendmentNotificationDocument,
    }[notificationType];

    await documentFetch(
      user.organizationId,
      accountOwnerId,
      accountId,
      notificationId, // Ambiguous ID for fetching the document
      user.token,
      user
    )
      .then(({ data }) => {
        const { bytes: base64 = '' } = data;
        const winURL = window.URL.createObjectURL(
          createFileBlobFromBase64(base64, 'application/pdf') || data
        );
        window.open(winURL, '_blank').focus();
      })
      .catch(() => {
        addGlobalMessage('Error fetching the selected PDF document');
      });
  }

  useEffect(() => {
    if (user.roles && accountId) {
      getAndSetAccountWithholdingNotifications();
      getAndSetAccountRmdNotifications();
      getAndSetAccountAmendmentNotifications();
      setUserRole(!user.roles.includes(UserRole.admin));
      setIsAccountOwner(user.roles.includes(UserRole.accountOwner));
    }

    return () => {
      isMounted = false;
    };
  }, [user.roles, accountId]);

  const columns = isAccountOwner
    ? ([
        {
          field: 'withholdingNotificationStatus',
          type: 'actions',
          width: 0,
          sortable: false,
          renderCell: (params: GridCellParams) => {
            const { row = {} } = params;
            const {
              withholdingNotificationStatus = '',
              notificationSent = '',
              rmdNotificationStatus = '',
              amendmentNotificationStatus = '',
            } = row;
            const [isFetchingDocument, setIsFetchingDocument] = useState(
              false as boolean
            );

            // Is rmd notification sent date populated or, if withholding notification, is it SENT status
            const isRowSent =
              notificationSent ||
              [
                WithholdingNotificationStatus.sent,
                WithholdingNotificationStatus.draft,
              ].includes(withholdingNotificationStatus) ||
              [
                WithholdingNotificationStatus.sent,
                WithholdingNotificationStatus.draft,
              ].includes(rmdNotificationStatus) ||
              [
                WithholdingNotificationStatus.sent,
                WithholdingNotificationStatus.draft,
              ].includes(amendmentNotificationStatus);

            return (
              isRowSent && (
                <Grid
                  container
                  wrap="nowrap"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Grid item>
                    <IconButton
                      disabled={isFetchingDocument}
                      color="primary"
                      size="small"
                      aria-label="Open latest pdf document"
                      onClick={async () => {
                        setIsFetchingDocument(true);
                        await openPdf(row);
                        setIsFetchingDocument(false);
                      }}
                    >
                      <PictureAsPdfIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )
            );
          },
        },
        {
          field: 'label',
          headerName: 'Type',
          width: 200,
        },
        {
          field: 'type',
          headerName: ' ',
          sortable: false,
          width: 128,
          valueFormatter: (params: GridValueFormatterParams) => {
            const { value = '' } = params;
            return (
              withholdingNotificationTypeLabels[
                value as WithholdingNotificationType
              ] ||
              rmdNotificationTypeLabels[value as RmdNotificationType] ||
              value
            );
          },
        },
        {
          field: 'notificationSent',
          headerName: 'Date Sent',
          width: 126,
          valueFormatter: dateValueFormatter,
        },
        {
          field: 'status',
          headerName: 'Status',
          width: 200,
          renderCell: (params: GridCellParams) => {
            const { value = '' } = params;
            const statusComponents = {
              [RmdNotificationStatus.sent]: (
                <Box display="flex" alignItems="center">
                  <CheckCircleIcon sx={{ color: 'success.main' }} />
                  <Typography
                    variant="body1"
                    sx={{ color: 'success.main', paddingLeft: 1 }}
                  >
                    Sent
                  </Typography>
                </Box>
              ),
              [RmdNotificationStatus.pending]: (
                <Box display="flex" alignItems="center">
                  <PendingIcon sx={{ color: 'warning.main' }} />
                  <Typography
                    variant="body1"
                    sx={{ color: 'warning.main', paddingLeft: 1 }}
                  >
                    Pending
                  </Typography>
                </Box>
              ),
              [RmdNotificationStatus.resolved]: (
                <Box display="flex" alignItems="center">
                  <CheckCircleIcon sx={{ color: 'success.main' }} />
                  <Typography
                    variant="body1"
                    sx={{ color: 'success.main', paddingLeft: 1 }}
                  >
                    Completed{' '}
                  </Typography>
                </Box>
              ),
              [RmdNotificationStatus.draft]: (
                <Box display="flex" alignItems="center">
                  <PendingIcon sx={{ color: 'warning.main' }} />
                  <Typography
                    variant="body1"
                    sx={{ color: 'warning.main', paddingLeft: 1 }}
                  >
                    Draft
                  </Typography>
                </Box>
              ),
              [RmdNotificationStatus.awaiting]: (
                <Box display="flex" alignItems="center">
                  <InfoIcon sx={{ color: 'info.main' }} />
                  <Typography
                    variant="body1"
                    sx={{ color: 'info.main', paddingLeft: 1 }}
                  >
                    Awaiting Election
                  </Typography>
                </Box>
              ),
            };

            return statusComponents[value as RmdNotificationStatus] || value;
          },
        },
      ] as GridColDef[])
    : ([
        {
          field: 'withholdingNotificationStatus',
          type: 'actions',
          width: 0,
          sortable: false,
          renderCell: (params: GridCellParams) => {
            const { row = {} } = params;
            const {
              withholdingNotificationStatus = '',
              notificationSent = '',
              rmdNotificationStatus = '',
              amendmentNotificationStatus = '',
            } = row;
            const [isFetchingDocument, setIsFetchingDocument] = useState(
              false as boolean
            );

            // Is rmd notification sent date populated or, if withholding notification, is it SENT status
            const isRowSent =
              notificationSent ||
              [
                WithholdingNotificationStatus.sent,
                WithholdingNotificationStatus.draft,
              ].includes(withholdingNotificationStatus) ||
              [
                WithholdingNotificationStatus.sent,
                WithholdingNotificationStatus.draft,
              ].includes(rmdNotificationStatus) ||
              [
                WithholdingNotificationStatus.sent,
                WithholdingNotificationStatus.draft,
              ].includes(amendmentNotificationStatus);

            return (
              isRowSent && (
                <Grid
                  container
                  wrap="nowrap"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Grid item>
                    <IconButton
                      disabled={isFetchingDocument}
                      color="primary"
                      size="small"
                      aria-label="Open latest pdf document"
                      onClick={async () => {
                        setIsFetchingDocument(true);
                        await openPdf(row);
                        setIsFetchingDocument(false);
                      }}
                    >
                      <PictureAsPdfIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )
            );
          },
        },
        {
          field: 'label',
          headerName: 'Type',
          width: 200,
        },
        {
          field: 'type',
          headerName: ' ',
          sortable: false,
          width: 128,
          valueFormatter: (params: GridValueFormatterParams) => {
            const { value = '' } = params;
            return (
              withholdingNotificationTypeLabels[
                value as WithholdingNotificationType
              ] ||
              rmdNotificationTypeLabels[value as RmdNotificationType] ||
              value
            );
          },
        },
        {
          field: 'notificationSent',
          headerName: 'Date Sent',
          width: 126,
          valueFormatter: dateValueFormatter,
        },
        {
          field: 'status',
          headerName: 'Status',
          width: 200,
          renderCell: (params: GridCellParams) => {
            const { value = '' } = params;
            const statusComponents = {
              [RmdNotificationStatus.sent]: (
                <Box display="flex" alignItems="center">
                  <CheckCircleIcon sx={{ color: 'success.main' }} />
                  <Typography
                    variant="body1"
                    sx={{ color: 'success.main', paddingLeft: 1 }}
                  >
                    Sent
                  </Typography>
                </Box>
              ),
              [RmdNotificationStatus.pending]: (
                <Box display="flex" alignItems="center">
                  <PendingIcon sx={{ color: 'warning.main' }} />
                  <Typography
                    variant="body1"
                    sx={{ color: 'warning.main', paddingLeft: 1 }}
                  >
                    Pending
                  </Typography>
                </Box>
              ),
              [RmdNotificationStatus.resolved]: (
                <Box display="flex" alignItems="center">
                  <CheckCircleIcon sx={{ color: 'success.main' }} />
                  <Typography
                    variant="body1"
                    sx={{ color: 'success.main', paddingLeft: 1 }}
                  >
                    Completed{' '}
                  </Typography>
                </Box>
              ),
              [RmdNotificationStatus.draft]: (
                <Box display="flex" alignItems="center">
                  <PendingIcon sx={{ color: 'warning.main' }} />
                  <Typography
                    variant="body1"
                    sx={{ color: 'warning.main', paddingLeft: 1 }}
                  >
                    Draft
                  </Typography>
                </Box>
              ),
              [RmdNotificationStatus.awaiting]: (
                <Box display="flex" alignItems="center">
                  <InfoIcon sx={{ color: 'info.main' }} />
                  <Typography
                    variant="body1"
                    sx={{ color: 'info.main', paddingLeft: 1 }}
                  >
                    Awaiting Election
                  </Typography>
                </Box>
              ),
            };

            return statusComponents[value as RmdNotificationStatus] || value;
          },
        },
        {
          field: 'deleteNotifications',
          type: 'actions',
          sortable: false,
          renderCell: (params: GridCellParams) => {
            const { row = {} } = params;

            return (
              <>
                <Grid container justifyContent="flex-end">
                  <IconButton
                    data-testid="DeleteNotificationButton"
                    size="small"
                    aria-label="Delete Notification"
                    onClick={() => {
                      deleteNotifications(row);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </>
            );
          },
        },
      ] as GridColDef[]);

  const rows: Array<
    WithholdingNotificationRow | RmdNotificationRow | AmendmentNotificationRow
  > = [
    ...withholdingNotifications,
    ...rmdNotifications,
    ...amendmentNotifications,
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DataGrid
            initialState={{
              columns: {
                columnVisibilityModel: {
                  // Hide columns based on user role
                  action: userRole,
                },
              },
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={globalPaginationOptions}
            onPaginationModelChange={setGlobalPageSize}
            components={{
              NoRowsOverlay: SiraNoRowsOverlay,
            }}
            disableColumnMenu
            autoHeight
            columns={columns}
            rows={rows.map((row, id) => ({ ...row, id }))}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AccountOwnerAccountWithholdingNotices;
