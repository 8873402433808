import axios from 'axios';
import format from 'string-format';

import { prepareBinaryJson, removeEmptyStrings, setBaseUrl } from './api.utils';
import {
  AccountOwnerResponse,
  AccountOwnerSearchResponse,
  AccountOwnerAccountsResponse,
  AccountOwnersResponse,
  AmortizationCalculationResponse,
  AmortRequestParams,
  AccountOwner,
  AccountOwnerUpdateImportColumnsResponse,
  AccountOwnerSymitarResponse,
} from './AccountOwnerApi.d';
import { AccountOwnerSearchForm } from '../components/form/accountSearch/AccountSearch';
import axiosInstance from '../utils/axiosInterceptor';

const urlBase = process.env.REACT_APP_API_BASE_URL;
const addAccountOwnerUrl = '/org/{0.orgId}/accountOwner';

const accountOwnerUrl = '/org/{0.orgId}/accountOwner/{0.accountOwnerId}';
const customerPortalUrl =
  '/api/customerPortal/org/{0.orgId}/accountOwner/{0.accountOwnerId}';
  const accountOwnerSearchUrl =
  '/api/org/{0.orgId}/accountOwner/search?text={0.query}';
  const accountOwnerSymitarSearchUrl =
  '/api/org/{0.orgId}/symitar/accountownerlookup?search={0.query}';

const accountOwnerAccountsUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/accounts';

const contributionLimitsUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerId}/contributionTotals?taxYear={0.taxYear}&type=left';
const amortizationCalculationUrl =
  '/api/org/{0.orgId}/accountOwner/{0.accountOwnerId}/account/{0.accountId}/amort';
const accountOwnerUpdateImportColumsUrl =
  '/api/org/{0.orgId}/upload/accountOwnerImportColumns';
const accountOwnerUpdateImportUrl =
  '/api/org/{0.orgId}/upload/accountOwnerUpdateImport';
const syncAccount= '/org/{0.orgId}/symitar/accountowner/{0.accountOwnerId}/refreshsymcache'

export async function getOrgAccountOwners(
  orgId: string,
  token: string,
  user: any
): Promise<AccountOwnersResponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${accountOwnerUrl}`, {
    orgId,
    accountOwnerId: '',
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getAccountOwner(
  accountOwnerId: string,
  orgId: string,
  token: string,
  user: any
): Promise<AccountOwnerResponse> {
  if (!accountOwnerId) {
    return null;
  }

  const url = `${urlBase}${format(`${setBaseUrl(user)}${accountOwnerUrl}`, {
    orgId,
    accountOwnerId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getCustomerPortalAccountOwner(
  accountOwnerId: string,
  orgId: string,
  token: string
): Promise<AccountOwnerResponse> {
  const url = `${urlBase}${format(customerPortalUrl, {
    orgId,
    accountOwnerId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

function accountOwnerRequestFactory(data: any): AccountOwner {
  const {
    accountOwnerId,
    addressLine1,
    addressLine2,
    addressLine3,
    city,
    country,
    dateOfBirth,
    dateOfDeath,
    emailAddress,
    financialOrganizationId,
    firstName,
    lastName,
    representativeFirstName,
    representativeLastName,
    name,
    middleInitial,
    phoneNumber,
    state,
    foreignStateProvinceRegion,
    taxpayerIdNumber,
    zip,
    relationship,
    eligibleDesignatedBeneficiary,
  } = data;

  return {
    accountOwnerId,
    addressLine1,
    addressLine2,
    addressLine3,
    city,
    country,
    dateOfBirth,
    dateOfDeath,
    emailAddress,
    financialOrganizationId,
    firstName,
    lastName,
    representativeFirstName,
    representativeLastName,
    name,
    middleInitial,
    phoneNumber,
    state,
    foreignStateProvinceRegion,
    taxpayerIdNumber,
    zip,
    relationship,
    eligibleDesignatedBeneficiary,
  };
}

export async function createOrSaveAccountOwner(
  data: AccountOwner,
  orgId: string,
  token: string,
  user: any
): Promise<AccountOwnerResponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${addAccountOwnerUrl}`, {
    orgId,
    accountOwnerId: data.accountOwnerId,
  })}`;

  let dataNorm = {
    ...data,
    financialOrganizationId: orgId,
    addressLine1: data.addressLine1.trim(),
  } as any;
  dataNorm = removeEmptyStrings(accountOwnerRequestFactory(dataNorm));

  if (data.accountOwnerId) {
    return axiosInstance.put(`${url}/${data.accountOwnerId}`, dataNorm, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return axiosInstance.post(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function syncAccountOwner(
  data: AccountOwner,
  orgId: string,
  token: string,
  user: any
): Promise<AccountOwnerResponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${syncAccount}`, {
    orgId,
    accountOwnerId: data.accountOwnerId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function searchAccountOwner(
  data: AccountOwnerSearchForm,
  orgId: string,
  token: string
): Promise<AccountOwnerSearchResponse> {
  const { query = '' } = data;
  const url = `${urlBase}${format(accountOwnerSearchUrl, { orgId, query })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function searchSimytarlocAccountOwner(
  data: AccountOwnerSearchForm,
  orgId: string,
  token: string
): Promise<AccountOwnerSymitarResponse> {
  const { query = '' } = data;
  const url = `${urlBase}${format(accountOwnerSymitarSearchUrl, { orgId, query })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getAccountOwnerAccounts(
  accountOwnerId: string,
  orgId: string,
  token: string,
  user: any
): Promise<AccountOwnerAccountsResponse> {
  const url = `${urlBase}${format(
    `${setBaseUrl(user)}${accountOwnerAccountsUrl}`,
    {
      orgId,
      accountOwnerId,
    }
  )}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getContributionLimit(
  accountOwnerId: string,
  taxYear: number,
  orgId: string,
  token: string,
  user: any
): Promise<AccountOwnerResponse> {
  const url = `${urlBase}${format(
    `${setBaseUrl(user)}${contributionLimitsUrl}`,
    {
      orgId,
      accountOwnerId,
      taxYear,
    }
  )}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getAmortizationCalculation(
  orgId: string,
  token: string,
  accountId: string,
  accountOwnerId: string,
  parameters: AmortRequestParams
): Promise<AmortizationCalculationResponse> {
  const url = `${urlBase}${format(amortizationCalculationUrl, {
    orgId,
    accountId,
    accountOwnerId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: parameters,
  });
}

export async function importAccountOwnerUpdateColumns(
  files: Array<File>,
  orgId: string,
  token: string
): Promise<AccountOwnerUpdateImportColumnsResponse> {
  const url = `${urlBase}${format(accountOwnerUpdateImportColumsUrl, {
    orgId,
  })}`;

  const formData = new FormData();
  formData.append('file', files[0]);

  return axiosInstance.post(url, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function importAccountOwnerUpdate(
  files: Array<File>,
  headerMappings: any,
  orgId: string,
  token: string
): Promise<any> {
  const url = `${urlBase}${format(accountOwnerUpdateImportUrl, {
    orgId,
  })}`;

  const formData = new FormData();

  formData.append('file', files[0]);
  if (headerMappings) {
    formData.append('headerMappings', prepareBinaryJson(headerMappings));
  }

  return axiosInstance.post(url, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function deleteAccountOwner(
  accountOwnerId: string,
  orgId: string,
  token: string,
  user: any
): Promise<AccountOwnerResponse> {
  if (!accountOwnerId) {
    return null;
  }

  const url = `${urlBase}${format(`${setBaseUrl(user)}${accountOwnerUrl}`, {
    orgId,
    accountOwnerId,
  })}`;

  return axiosInstance.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
