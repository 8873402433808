import React, { useEffect, useMemo } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import {
  isValid,
  differenceInDays,
  startOfYear,
  subYears,
  parseISO,
  endOfDay,
  isAfter,
} from 'date-fns';
import { Box } from '@mui/material';

import moment from 'moment';
import { distributionDateValidation } from '../SiraDateField';
import {
  AccountContribution,
  DepositCheckPayee,
  DepositMethod,
  DepositType,
  PostponementCode,
  PostponementReason,
  RepaymentCode,
} from '../../../api/ContributionApi.d';
import StepButtonBar from '../../steps/StepButtonBar';
import { Account, AccountType } from '../../../api/AccountApi.d';
import DepositMethodForm from '../contribution/DepositMethodForm';
import DepositTypeForm from '../contribution/DepositTypeForm';
import DepositAmountForm from '../contribution/DepositAmountForm';
import DepositorForm from '../contribution/DepositorForm';
import TransactionSubStepper, {
  TransactionSubStep,
} from '../../../page/TransactionSubStepper';
import { ExternalAccount } from '../../../api/ExternalAccountApi.d';
import { AccountOwner } from '../../../api/AccountOwnerApi.d';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import { useUser } from '../../../auth/useUser';
import { getAccountOwner } from '../../../api/AccountOwnerApi';
import { errorMessages } from '../../../utils/errorhandling.utils';

const currentYear = new Date().getFullYear();

export const CONTRIBUTION_INFO_INIT: AccountContribution = {
  accountId: '',
  amount: 0,
  depositMethod: '' as DepositMethod,
  depositType: '' as DepositType,
  effectiveDate: moment().format('YYYY-MM-DD'),
  payableTo: '' as DepositCheckPayee,
  dateOnCheck: '',
  fromAccountNumber: '',
  fromAccountType: '',
  fromFinancialOrganization: '',
  postponedCode: '' as PostponementCode,
  postponedReason: '' as PostponementReason,
  repaymentCode: '' as RepaymentCode,
  taxYear: currentYear,
  dateOfWithdrawal: '',
  depositorFirstName: '',
  depositorLastName: '',
  depositorPhoneNumber: '',
  depositorEmailAddress: '',
  postponedYear: currentYear,
};

function getDistributionInfoSchema(
  accountClosedDate: string,
  accountType: AccountType,
  inheritedAccount: boolean,
  symitar: boolean,
) {
  const minEffectiveDate = subYears(startOfYear(new Date()), 1); // After start of last year
  const maxEffectiveDate = accountClosedDate // if account closed, before or on account close. Else use validation default
    ? endOfDay(parseISO(accountClosedDate))
    : new Date(currentYear, 12, 1);
  const depositorFirstNameBaseSchema = yup.string().max(30).label('First Name');
  const depositorLastNameBaseSchema = yup.string().max(30).label('Last Name');

  return yup.object({
    depositMethod: yup.string().required().label('Payment Method'),
    depositType: yup.string().required().label('Deposit Type'),
    depositorFirstName: [AccountType.esa].includes(accountType)
      ? depositorFirstNameBaseSchema.required()
      : depositorFirstNameBaseSchema,
    depositorLastName: [AccountType.esa].includes(accountType)
      ? depositorLastNameBaseSchema.required()
      : depositorLastNameBaseSchema,
    amount: yup
      .number()
      .required()
      .label('Amount')
      .when('maxRemainingAmount', (maxRemainingAmount, schema) => {
        return maxRemainingAmount || maxRemainingAmount === 0
          ? schema.max(maxRemainingAmount)
          : schema;
      }),
    effectiveDate: distributionDateValidation(
      minEffectiveDate,
      maxEffectiveDate,
      accountClosedDate,
    )
      .required()
      .label('Deposit Date'),
    payableTo: yup
      .string()
      .label('Payable To')
      .when('depositMethod', (type, schema) =>
        [DepositMethod.check].includes(type) && !inheritedAccount
          ? schema.required()
          : schema,
      ),
    dateOnCheck: yup
      .string()
      .label('Date on Check')
      .when('depositMethod', (type, schema) =>
        [DepositMethod.check].includes(type) ? schema.required() : schema,
      ),
    fromAccountNumber: yup
      .string()
      .label('Account Number')
      .when('depositMethod', (type, schema) =>
        // Account number can be optional here for external accounts but not direct
        [DepositMethod.direct].includes(type) && !inheritedAccount && !symitar
          ? schema.required()
          : schema,
      ),
    fromAccountType: yup
      .string()
      .label('Source Account Type')
      .when('depositMethod', (type, schema) =>
        [
          DepositMethod.direct,
          DepositMethod.transfer,
          DepositMethod.check,
        ].includes(type) &&
        !inheritedAccount &&
        !symitar
          ? schema.required()
          : schema,
      ),
    fromFinancialOrganization: yup
      .string()
      .label('Source Financial Org')
      .when('depositMethod', (type, schema) =>
        [DepositMethod.transfer].includes(type) ? schema.required() : schema,
      ),
    taxYear: yup
      .number()
      .label('Tax Year')
      .min(currentYear - 8)
      .max(currentYear)
      .when('depositType', (type, schema) =>
        [DepositType.postponed].includes(type) ? schema.required() : schema,
      ),
    repaymentCode: yup
      .string()
      .label('Repayment Code')
      .when('depositType', (type, schema) =>
        [DepositType.repayment].includes(type) ? schema.required() : schema,
      ),
    postponedCode: yup
      .string()
      .label('Postpone Code')
      .when('depositType', (type, schema) =>
        [DepositType.postponed].includes(type) ? schema.required() : schema,
      ),
    postponedReason: yup
      .string()
      .label('Postpone Reason')
      .when('postponedCode', (code, schema) =>
        [PostponementCode.executiveOrder, PostponementCode.publicLaw].includes(
          code,
        )
          ? schema.required()
          : schema,
      ),
    postponedYear: yup
      .number()
      .label('Postponed Year')
      .min(currentYear - 8)
      .max(currentYear)
      .when('postponedCode', (type, schema) =>
        [
          PostponementCode.executiveOrder,
          PostponementCode.publicLaw,
          PostponementCode.fedDisasterArea,
        ].includes(type)
          ? schema.required()
          : schema,
      ),
  });
}

export interface ContributionInfoFormProps {
  amount?: number;
  account: Account;
  deceasedAccount?: Account & ExternalAccount;
  initialValues: AccountContribution;
  onSubmit?: Function;
  onCancel?: Function;
  submitName?: string;
  explodeSteps?: boolean; // Should we skip the stepper and show all steps expanded at once?
  accountOwner?: AccountOwner;
}

function dateOfWithdrawalValidation(values) {
  const errors = {} as any;
  const {
    dateOfWithdrawal,
    effectiveDate,
    dateOnCheck,
    depositType,
    depositMethod,
  } = values;
  const isCheck = depositMethod === DepositMethod.check;
  const isRollover = depositType === DepositType.rollover;

  if (!isCheck && isRollover) {
    if (!isValid(parseISO(dateOfWithdrawal))) {
      errors.dateOfWithdrawal = 'Withdrawal Date Required';
    } else if (isAfter(parseISO(dateOfWithdrawal), parseISO(effectiveDate))) {
      errors.dateOfWithdrawal =
        'Withdrawal date must be on or before deposit date';
    }
  }

  // Any check deposit should not allow the check date to be before the deposit date
  if (isCheck && isAfter(parseISO(dateOnCheck), parseISO(effectiveDate))) {
    errors.dateOnCheck = 'Date on check must be on or before the deposit date';
  }

  return errors;
}

function sixtyDayLogicValidation(values) {
  const errors = {} as any;
  const {
    effectiveDate,
    dateOnCheck,
    dateOfWithdrawal,
    depositMethod,
    depositType,
    fromAccountType,
  } = values;
  const isCheck = depositMethod === DepositMethod.check;
  const isRollover = depositType === DepositType.rollover;

  if (
    isRollover &&
    isValid(parseISO(effectiveDate)) &&
    fromAccountType !== AccountType.employer
  ) {
    const afterDeadlineMessage =
      'Rollover must be completed within 60-days of the original distribution';

    if (
      !isCheck &&
      isValid(parseISO(dateOfWithdrawal)) &&
      differenceInDays(parseISO(effectiveDate), parseISO(dateOfWithdrawal)) > 60
    ) {
      errors.dateOfWithdrawal = afterDeadlineMessage;
    }

    // For Check rollover, compare the effectiveDate as withdrawal date won't be relevant
    if (
      isCheck &&
      isValid(parseISO(dateOnCheck)) &&
      differenceInDays(parseISO(effectiveDate), parseISO(dateOnCheck)) > 60
    ) {
      errors.depositType = afterDeadlineMessage; // Show this error later when specifying a rollover
    }
  }

  return errors;
}

function additionalValidation(values) {
  return {
    ...dateOfWithdrawalValidation(values),
    ...sixtyDayLogicValidation(values),
  };
}

function ContributionInfoForm({
  amount,
  account = {} as Account,
  deceasedAccount = {} as Account,
  initialValues,
  onSubmit,
  onCancel,
  submitName,
  explodeSteps = false,
  accountOwner,
}: ContributionInfoFormProps) {
  const {
    accountOwnerId,
    accountType,
    closedDate: accountClosedDate,
  } = account;
  const hasDeceasedAccount: boolean = Boolean(
    deceasedAccount.accountId || deceasedAccount.externalAccountOwnerAccountId,
  );
  const isInheritedAccount: boolean = [
    AccountType.inheritedRoth,
    AccountType.inheritedTraditional,
  ].includes(accountType);
  let isMounted = true;
  const [activeStep, setActiveStep] = React.useState(0);

  const { organization, addGlobalMessage, customerPortalUser } =
    useGlobalContext();
  const { user } = useUser();
  const [accountOwnerValue, setAccountOwner] = React.useState(
    {} as AccountOwner,
  );
  const CONTRIBUTION_SCHEMA = useMemo(() => {
    const symitar = organization.coreIntegration === 'SYMITAR';
    return getDistributionInfoSchema(
      accountClosedDate,
      accountType,
      hasDeceasedAccount,
      symitar,
    );
  }, [accountClosedDate, accountType]);

  useEffect(() => {
    if (accountOwner && accountOwner.accountOwnerId) {
      setAccountOwner(accountOwner);
    }
    return () => {
      isMounted = false;
    };
  }, [accountOwner]);

  const allowedDepositMethods = () => {
    // if this evaluates true return the allowed deposits
    if (customerPortalUser) {
      return [
        DepositMethod.check,
        DepositMethod.direct,
        DepositMethod.transfer,
      ];
    }
    return isInheritedAccount
      ? [DepositMethod.check, DepositMethod.direct, DepositMethod.transfer]
      : undefined;
  };

  const contributionSubSteps: Array<TransactionSubStep> = [
    {
      label: 'Date and Method of Deposit',
      stepContent: (
        <DepositMethodForm
          account={account}
          allowedDepositMethods={allowedDepositMethods()}
          accountOwner={accountOwnerValue}
        />
      ),
      validationFields: [
        'effectiveDate',
        'depositMethod',
        'payableTo',
        'dateOnCheck',
        'fromAccountNumber',
        'fromAccountType',
        'fromFinancialOrganization',
      ],
    },
    {
      label: 'Type of Deposit',
      stepContent: (
        <DepositTypeForm account={account} accountOwner={accountOwnerValue} />
      ),
      validationFields: [
        'depositType',
        'taxYear',
        'postponedCode',
        'postponedReason',
        'repaymentCode',
        'dateOfWithdrawal',
        'postponedYear',
      ],
    },
    {
      label: 'Amount of Deposit',
      stepContent: (
        <DepositAmountForm
          amount={amount}
          accountType={accountType}
          accountOwnerId={accountOwnerId}
        />
      ),
      validationFields: [],
    },
  ];

  if ([AccountType.esa].includes(accountType)) {
    contributionSubSteps.unshift({
      label: 'Who is making the deposit?',
      stepContent: (
        <Box mt={2}>
          <DepositorForm
            responsibleFirstName={account.responsibleFirstName}
            responsibleLastName={account.responsibleLastName}
            responsibleEmailAddress={account.responsibleEmailAddress}
            responsiblePhoneNumber={account.responsiblePhoneNumber}
          />
        </Box>
      ),
      validationFields: [
        'depositorFirstName',
        'depositorLastName',
        'depositorPhoneNumber',
        'depositorEmailAddress',
      ],
    });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, actions) => {
        const result = await onSubmit(values);
        if (result) {
          actions.setErrors(result.errors);
        }
      }}
      enableReinitialize
      validateOnMount
      validationSchema={CONTRIBUTION_SCHEMA}
      validate={additionalValidation}
    >
      {({ isSubmitting, setValues, values, errors }) => {
        useEffect(() => {
          if (hasDeceasedAccount) {
            // Merge both of the employer-sponsored (roth/traditional) types into one for contribution sources
            const inheritedFromAccountType = [
              AccountType.employerRoth,
              AccountType.employerTraditional,
            ].includes(deceasedAccount.accountType)
              ? AccountType.employer
              : deceasedAccount.accountType;

            setValues({
              ...values,
              fromAccountNumber:
                values.fromAccountNumber || deceasedAccount.accountNumber || '', // Infer from deceased if passed in
              fromAccountType:
                values.fromAccountType ||
                inheritedFromAccountType ||
                ('' as AccountType), // Infer from deceased id passed in
            });
          }
        }, [hasDeceasedAccount]);

        return (
          <Form>
            <TransactionSubStepper
              steps={contributionSubSteps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              explodeSteps={explodeSteps}
            />
            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              onCancel={onCancel}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default ContributionInfoForm;
