import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import FormPaper from '../FormPaper';
import Layout from '../Layout';
import TransactionStepper, {
  TransactionStep,
} from '../../page/TransactionStepper';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import OrganizationSearchForm from '../form/orgSearch/OrganizationSearchForm';
import { skipStep, useTransactionReducer } from '../../page/TransactionReducer';
import OrganizationSearchResults from '../form/orgSearch/OrganizationSearchResults';
import { OrganizationSearchResponse } from '../../api/OrganizationApi.d';
import { useUnsavedChangesWarning } from '../useUnsavedChangesWarning';
import ObscuredTaxId, { TaxIdFormat } from '../ObscuredTaxId';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useUser } from '../../auth/useUser';
import { getOrg } from '../../api/OrganizationApi';
import { useGlobalContext } from '../../auth/useGlobalContext';
import { SiraSelectItem } from '../form/SiraSelectField';
import {
  createMerger,
  getAccountMergerCounts,
} from '../../api/OrganizationMergerApi';
import { errorMessages } from '../../utils/errorhandling.utils';

interface FinancialOrganization {
  financialOrganizationId: number;
  financialOrganizationName: string;
  financialOrganizationLegalName: string;
  financialOrganizationFederalTaxId: string;
  primaryBranchStateAbbreviation: string;
  primaryBranchStateName: string;
  fiduciaryType: string;
  agreementDate: string; // Consider using Date if you plan to handle this as a Date object
  solution: string;
  status: string;
  accountTypesSupported: string[];
  rmdNotificationsEnabled: boolean;
  withholdingNotificationsEnabled: boolean;
  id: number;
}

function OrgCreateMerger() {
  const [pageState] = useTransactionReducer();
  const { user } = useUser();
  const { taxYears } = useGlobalContext();

  const [response, setResponse] = useState({});
  const { UnsavedChangesPrompt, setUnsavedChanges } =
    useUnsavedChangesWarning();
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [selectedOrg, setSelectedOrg] = useState(null as any);
  const [primaryBranchInfo, setPrimaryBranchInfo] = useState({} as any);
  const [mergingOrg, setMergingOrg] = useState(null as any);
  const [mergingPrimaryBranchInfo, setMergingPrimaryBranchInfo] = useState(
    {} as any,
  );
  const [taxYearDropdown, setTaxYearDropdown] = useState(
    [] as Array<SiraSelectItem>,
  );
  const [excludeTaxForm1099, setExcludeTaxForm1099] = useState(false);
  const [excludeTaxForm5498, setExcludeTaxForm5498] = useState(false);
  const [startTaxYear, setStartTaxYear] = useState('');
  const [endTaxYear, setEndTaxYear] = useState('');
  const { addGlobalMessage } = useGlobalContext();
  const [mergingOrgCount, setMergingOrgCount] = useState(0);
  const [selectedOrgCount, setSelectedOrgCount] = useState(0);
  const [mergingOrgresponse, setMergingOrgResponse] = useState({});
  const [effectiveDate, setEffectiveDate] = useState('');

  const theme = useTheme();

  useEffect(() => {
    if (taxYears) {
      setTaxYearDropdown(
        taxYears.map((availableYears) => ({
          value: availableYears.taxYear,
          label: availableYears.taxYear.toString(),
        })),
      );
    }
  }, [taxYears]);

  const mergeORganization = async () => {
    setIsLoading(true);
    //create a new merger
    const parentOrgId = selectedOrg.financialOrganizationId;

    const data = {
      financialOrganizationId: parentOrgId,
      mergingFinancialOrganizationId: mergingOrg.financialOrganizationId,
      taxYearStart: startTaxYear,
      taxYearEnd: endTaxYear,
      mergerEffectiveDate: effectiveDate,
    };

    const params = {
      exclude1099: excludeTaxForm1099,
      exclude5498: excludeTaxForm5498,
    };

    await createMerger(data, params, parentOrgId, user.token)
      .then((res) => {
        setIsLoading(false);
        addGlobalMessage('Merger successfully created', {
          severity: 'success',
        });
        //skip to the end
        skipStep(3);
      })
      .catch((err) => {
        setIsLoading(false);
        addGlobalMessage(
          errorMessages(err) || 'Error fetching organization information',
        );
      });
  };

  // Fetch the org data for the current user's org
  async function selectOrgAndGoToProfile(org) {
    setIsLoading(true);
    await getOrg(org.financialOrganizationId, user.token, user).then((res) => {
      const branchInfo = res.data.branches.find(
        ({ primaryBranch = false }) => primaryBranch,
      );
      setPrimaryBranchInfo(branchInfo);
      setSelectedOrg(res.data);
      setIsLoading(false);
    });
    skipStep(1);
  }

  // Fetch the org data for the current user's org
  async function mergingOrganizations(org) {
    setIsLoading(true);
    await getOrg(org.financialOrganizationId, user.token, user).then((res) => {
      const branchInfo = res.data.branches.find(
        ({ primaryBranch = false }) => primaryBranch,
      );
      setMergingPrimaryBranchInfo(branchInfo);
      setMergingOrg(res.data);
      getMergingOrgCount(res.data.financialOrganizationId);
    });
    skipStep(2);
  }

  async function getMergingOrgCount(orgId) {
    const data = {
      survivingOrgId: selectedOrg.financialOrganizationId,
      mergingOrgId: orgId,
    };
    await getAccountMergerCounts(data, user.token)
      .then((res) => {
        res.data.map((account) => {
          if (account.orgId === parseInt(orgId)) {
            setMergingOrgCount(account.accounts);
          } else if (
            account.orgId === parseInt(selectedOrg.financialOrganizationId)
          ) {
            setSelectedOrgCount(account.accounts);
          }
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        addGlobalMessage(
          errorMessages(err) || 'Error fetching organization information',
        );
      });
  }

  const classes = {
    profileCard: {
      color: theme.palette.grey[300],
      marginTop: theme.spacing(2),
      padding: theme.spacing(4),
      background: theme.palette.primary.main,
      height: '100%',
    },
    moreInformation: {
      color: theme.palette.grey[300],
      paddingTop: theme.spacing(1),
      background: theme.palette.primary.main,
    },
  };

  const mergerSteps: Array<TransactionStep> = [
    {
      label: 'Select Surviving Organization',
      stepContent: (
        <Box width="1" mt={4} mb={4}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Box mt={2} mb={4}>
                <OrganizationSearchForm setResponse={setResponse} />
              </Box>
            </Grid>
          </Grid>
          <OrganizationSearchResults
            onResultClick={selectOrgAndGoToProfile}
            response={response as OrganizationSearchResponse}
          />
        </Box>
      ),
    },
    {
      label: 'Select Merging Organization',
      stepContent: (
        <Box width="1" mt={4} mb={4}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Box mt={2} mb={4}>
                <OrganizationSearchForm setResponse={setMergingOrgResponse} />
              </Box>
            </Grid>
          </Grid>
          <OrganizationSearchResults
            onResultClick={mergingOrganizations}
            response={mergingOrgresponse as OrganizationSearchResponse}
          />
        </Box>
      ),
    },
    {
      label: 'Additional Merging Information',
      stepContent: (
        <Box width="1" mt={4} mb={4}>
          {/* these components will be used for the tax start year and end year */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <TextField
                type="date"
                name="effectiveDate"
                label="Merger Effective Date"
                fullWidth
                value={effectiveDate}
                onChange={(e) => {
                  setEffectiveDate(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="taxYear"
                label="Starting Tax Year"
                fullWidth
                onChange={(e) => {
                  setStartTaxYear(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="taxYear"
                label="Ending Tax Year"
                fullWidth
                onChange={(e) => {
                  setEndTaxYear(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                name="excludeTaxForm1099"
                label="Exclude from 1099 Tax Reporting"
                checked={excludeTaxForm1099}
                control={
                  <Checkbox
                    onChange={() => {
                      setExcludeTaxForm1099(!excludeTaxForm1099);
                    }}
                    color="primary"
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label="Exclude from 5498 Tax Reporting"
                checked={excludeTaxForm5498}
                control={
                  <Checkbox
                    onChange={() => {
                      setExcludeTaxForm5498(!excludeTaxForm5498);
                    }}
                    color="primary"
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Box pt={3}>
                {/* Form Submit */}
                <Button
                  type="submit"
                  disabled={isLoading}
                  variant="contained"
                  color="primary"
                  sx={{
                    mr: 2,
                  }}
                  data-qa="step-submit-button"
                  onClick={() => {
                    mergeORganization();
                  }}
                >
                  Create Merger
                </Button>

                <Button
                  disabled={isLoading}
                  variant="outlined"
                  sx={{
                    mr: 2,
                  }}
                  data-qa="step-cancel-button"
                  onClick={() => {
                    skipStep(0);
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ),
    },
  ];

  return (
    <Layout>
      <FormPaper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="overline" gutterBottom>
              Organizational Merger
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="secondary" variant="h1">
              Create Merger
            </Typography>
          </Grid>
          {mergingOrg && (
            <Grid item xs={5} md={5}>
              <Paper elevation={3} sx={classes.profileCard}>
                <>
                  <Typography variant="overline" color="secondary">
                    Merging Organization Name
                  </Typography>

                  <Typography color="secondary" variant="h3" gutterBottom>
                    {mergingOrg.name}
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>
                      <Typography
                        variant="subtitle2"
                        color="secondary"
                        gutterBottom
                      >
                        Legal Name
                      </Typography>

                      <Typography variant="body1">
                        {mergingOrg.legalName}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="subtitle2"
                        color="secondary"
                        gutterBottom
                      >
                        Federal Tax ID
                      </Typography>

                      <Typography variant="body1">
                        <ObscuredTaxId
                          taxId={mergingOrg.federalTaxId}
                          format={TaxIdFormat.ein}
                        />
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <Typography
                        variant="subtitle2"
                        color="secondary"
                        gutterBottom
                      >
                        Org Number
                      </Typography>

                      <Typography variant="body1">
                        {mergingOrg.financialOrganizationId}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle2"
                        color="secondary"
                        gutterBottom
                      >
                        Number of Accounts
                      </Typography>

                      <Typography variant="body1">{mergingOrgCount}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Accordion elevation={0} sx={classes.moreInformation}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                        >
                          <Typography variant="h5" color="secondary">
                            More Information
                          </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Grid container spacing={3}>
                            {mergingPrimaryBranchInfo && (
                              <>
                                <Grid item xs={12} md={6}>
                                  <Typography variant="h6">
                                    Mailing Address
                                  </Typography>
                                  <Typography variant="body1">
                                    {mergingPrimaryBranchInfo.addressLine1}
                                  </Typography>
                                  <Typography variant="body1">
                                    {mergingPrimaryBranchInfo.addressLine2}
                                  </Typography>
                                  <Typography variant="body1">
                                    {mergingPrimaryBranchInfo.city}{' '}
                                    {mergingPrimaryBranchInfo.state}{' '}
                                    {mergingPrimaryBranchInfo.zip}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Typography variant="h6">
                                    Phone Number
                                  </Typography>
                                  <Typography variant="body1">
                                    {mergingPrimaryBranchInfo.phoneNumber}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Typography variant="h6">Email</Typography>
                                  <Typography variant="body1">
                                    {mergingPrimaryBranchInfo.emailAddress}
                                  </Typography>
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                </>
              </Paper>
            </Grid>
          )}
          {selectedOrg && mergingOrg && (
            <Grid
              item
              xs={2}
              md={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ArrowForwardIcon color="secondary" style={{ fontSize: 48 }} />
            </Grid>
          )}
          {selectedOrg && (
            <Grid item xs={5} md={5}>
              <Paper elevation={3} sx={classes.profileCard}>
                <>
                  <Typography variant="overline" color="secondary">
                    Surviving Organization Name
                  </Typography>

                  <Typography color="secondary" variant="h3" gutterBottom>
                    {selectedOrg.name}
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>
                      <Typography
                        variant="subtitle2"
                        color="secondary"
                        gutterBottom
                      >
                        Legal Name
                      </Typography>

                      <Typography variant="body1">
                        {selectedOrg.legalName}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="subtitle2"
                        color="secondary"
                        gutterBottom
                      >
                        Federal Tax ID
                      </Typography>

                      <Typography variant="body1">
                        <ObscuredTaxId
                          taxId={selectedOrg.federalTaxId}
                          format={TaxIdFormat.ein}
                        />
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <Typography
                        variant="subtitle2"
                        color="secondary"
                        gutterBottom
                      >
                        Org Number
                      </Typography>

                      <Typography variant="body1">
                        {selectedOrg.financialOrganizationId}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle2"
                        color="secondary"
                        gutterBottom
                      >
                        Number of Accounts
                      </Typography>

                      <Typography variant="body1">
                        {selectedOrgCount}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Accordion elevation={0} sx={classes.moreInformation}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                        >
                          <Typography variant="h5" color="secondary">
                            More Information
                          </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Grid container spacing={3}>
                            {primaryBranchInfo && (
                              <>
                                <Grid item xs={12} md={6}>
                                  <Typography variant="h6">
                                    Mailing Address
                                  </Typography>
                                  <Typography variant="body1">
                                    {primaryBranchInfo.addressLine1}
                                  </Typography>
                                  <Typography variant="body1">
                                    {primaryBranchInfo.addressLine2}
                                  </Typography>
                                  <Typography variant="body1">
                                    {primaryBranchInfo.city}{' '}
                                    {primaryBranchInfo.state}{' '}
                                    {primaryBranchInfo.zip}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Typography variant="h6">
                                    Phone Number
                                  </Typography>
                                  <Typography variant="body1">
                                    {primaryBranchInfo.phoneNumber}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Typography variant="h6">Email</Typography>
                                  <Typography variant="body1">
                                    {primaryBranchInfo.emailAddress}
                                  </Typography>
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                </>
              </Paper>
            </Grid>
          )}
          <Grid item xs={12} pt={5}>
            <Box pt={8}>
              <TransactionStepper
                steps={mergerSteps}
                activeStep={pageState.activeStep}
                isLoading={isLoading}
                onStepClick={(index) => {
                  skipStep(index);
                }}
              />
            </Box>

            {UnsavedChangesPrompt}
          </Grid>
        </Grid>
      </FormPaper>
    </Layout>
  );
}

export default OrgCreateMerger;
