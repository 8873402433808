import React, { useState } from 'react';

import { Box, Paper, Typography, Button, Popover, Grid } from '@mui/material';

import {
  OrganizationSearchResponse,
  Solution,
} from '../../../api/OrganizationApi.d';
import ObscuredTaxId, { TaxIdFormat } from '../../ObscuredTaxId';
import PluralizedString from '../../PluralizedString';
import SiraNoRowsOverlay from '../../SiraNoRowsOverlay';
import { usePaginationContext } from '../../../auth/useGlobalContext';
import {
  globalPaginationOptions,
  OrgInformationStatus,
} from '../../../app.constants';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import Cancel from '@mui/icons-material/Cancel';

import { DataGridPro, GridCellParams, GridColDef } from '@mui/x-data-grid-pro';

interface OrganizationSearchResultsProps {
  response: OrganizationSearchResponse;
  onResultClick?: Function;
}

export function OrganizationSearchResults(
  props: OrganizationSearchResultsProps,
) {
  const { response = {}, onResultClick = () => {} } = props;
  const { setGlobalPageSize, globalPageSize } = usePaginationContext();
  const { data: results = [], query = '' } =
    response as OrganizationSearchResponse;
  const classes = {
    organizationLink: {
      justifyContent: 'flex-start' as any,
      width: '100%',
      textDecoration: 'none' as any,
      textTransform: 'none' as any,
    },
  };

  const columns: GridColDef[] = [
    {
      field: 'financialOrganizationName',
      headerName: 'Name',
      flex: 2,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            data-qa="searchResult_button"
            onClick={() => {
              onResultClick(row);
            }}
            sx={classes.organizationLink}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'financialOrganizationId',
      headerName: 'ID',
      flex: 0.5,
    },
    {
      field: 'financialOrganizationFederalTaxId',
      headerName: 'Federal Tax ID',
      flex: 1,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ein} />
        );
      },
    },
    {
      field: 'primaryBranchStateAbbreviation',
      headerName: 'State',
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const { value = '', row = {} } = params;
        const [orgStateAnchor, setOrgStateAnchor] =
          useState<HTMLButtonElement | null>(null);

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          setOrgStateAnchor(event.currentTarget);
        };

        const handleClose = () => {
          setOrgStateAnchor(null);
        };

        return (
          <>
            <Grid container alignItems="center" justifyContent="center">
              <Button onClick={handleClick}>
                <Typography variant="body2">{value.toString()}</Typography>
              </Button>
            </Grid>
            <Popover
              elevation={3}
              open={Boolean(orgStateAnchor)}
              onClose={handleClose}
              anchorEl={orgStateAnchor}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
            >
              <Box p={2}>
                <Typography variant="body2">
                  {row.primaryBranchStateName}
                </Typography>
              </Box>
            </Popover>
          </>
        );
      },
    },
    {
      field: 'solution',
      headerName: 'Solution',
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        // setting the typograpy based on the color sent in for the solution
        // eslint-disable-next-line no-nested-ternary
        const colorValue =
          // eslint-disable-next-line no-nested-ternary
          value === Solution.silver
            ? '#938D8A'
            : value === Solution.green
              ? '#368E64'
              : '#0C1F2A';
        return (
          <Typography variant="body2" style={{ color: colorValue }}>
            {value.toString()}
          </Typography>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;
        const statusComponents = {
          [OrgInformationStatus.active]: (
            <Box display="flex" alignItems="center">
              <CheckCircleIcon sx={{ color: 'success.main' }} />
              <Typography
                variant="body1"
                sx={{ color: 'success.main', paddingLeft: 1 }}
              >
                Active
              </Typography>
            </Box>
          ),
          [OrgInformationStatus.implementing]: (
            <Box display="flex" alignItems="center">
              <PendingIcon sx={{ color: 'warning.main' }} />
              <Typography
                variant="body1"
                sx={{ color: 'warning.main', paddingLeft: 1 }}
              >
                Implementing
              </Typography>
            </Box>
          ),
          [OrgInformationStatus.inactive]: (
            <Box display="flex" alignItems="center">
              <Cancel sx={{ color: 'error.main' }} />
              <Typography
                variant="body1"
                sx={{ color: 'error.main', paddingLeft: 1 }}
              >
                Inactive
              </Typography>
            </Box>
          ),
          [OrgInformationStatus.merging]: (
            <Box display="flex" alignItems="center">
              <InfoIcon sx={{ color: 'info.main' }} />
              <Typography
                variant="body1"
                sx={{ color: 'info.main', paddingLeft: 1 }}
              >
                Merging{' '}
              </Typography>
            </Box>
          ),
        };

        return (
          statusComponents[row.status as OrgInformationStatus] || row.status
        );
      },
    },
  ];

  const rows = results.map((result, id) => {
    return {
      ...result,
      id,
    };
  });

  return (
    <>
      <Box mb={4}>
        <Typography variant="body1">Showing results for</Typography>

        <Typography variant="h5">&quot;{query}&quot;</Typography>
      </Box>

      <Paper elevation={3}>
        <Box p={2}>
          <Typography variant="body1">
            {results.length} financial{' '}
            <PluralizedString noun="organization" quantity={results.length} />{' '}
            found
          </Typography>

          <Box mt={4}>
            <DataGridPro
              slots={{
                noRowsOverlay: SiraNoRowsOverlay,
              }}
              autoHeight
              columns={columns}
              initialState={{
                pagination: { paginationModel: { pageSize: globalPageSize } },
              }}
              pagination
              pageSizeOptions={globalPaginationOptions}
              onPaginationModelChange={setGlobalPageSize}
              rows={rows}
            />
          </Box>
        </Box>
      </Paper>
    </>
  );
}

export default OrganizationSearchResults;
