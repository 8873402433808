import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import Layout from '../Layout';
import PaperlessPage from '../PaperlessPage';
import { useLocation } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import ObscuredTaxId, { TaxIdFormat } from '../ObscuredTaxId';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import StartOutlinedIcon from '@mui/icons-material/StartOutlined';
import JoinInnerOutlinedIcon from '@mui/icons-material/JoinInnerOutlined';
import JoinFullOutlinedIcon from '@mui/icons-material/JoinFullOutlined';

import { getOrg } from '../../api/OrganizationApi';
import {
  createAmendments,
  getAccountMergerCounts,
  getIndividualMerger,
  updateAccountOwners,
  updateMerger,
  updateSharedAccounts,
} from '../../api/OrganizationMergerApi';
import { useUser } from '../../auth/useUser';
import { errorMessages } from '../../utils/errorhandling.utils';
import { useGlobalContext } from '../../auth/useGlobalContext';
import {
  mergerArray,
  mergeStatus,
  MergingOrgStatus,
} from '../../app.constants';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { StepIconProps } from '@mui/material/StepIcon';

enum AmendmentNotificationType {
  oneWay = 'UNIVERSAL_ONE_WAY_MERGER',
  twoWay = 'UNIVERSAL_TWO_WAY_MERGER',
}

const selectMergerAmendmentNotificationType = [
  {
    value: AmendmentNotificationType.oneWay,
    label: 'One Way Merger',
  },
  {
    value: AmendmentNotificationType.twoWay,
    label: 'Two Way Merger',
  },
];
interface MergerStatusStepperProps {
  mergerStatus: MergingOrgStatus;
}

interface EditMergerInformationProps {
  setIsEditing: Function;
  taxStartYear: string;
  taxEndYear: string;
  effective: string;
  updateMergeOrganization: Function;
  isLoading: boolean;
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800],
    }),
  },
}));

// setting the icon to the center of the button
const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...theme.applyStyles('dark', {
    backgroundColor: theme.palette.grey[700],
  }),
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement<unknown> } = {
    1: <StartOutlinedIcon />,
    2: <JoinInnerOutlinedIcon />,
    3: <JoinFullOutlinedIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const MergerStatusStepper: React.FC<MergerStatusStepperProps> = ({
  mergerStatus,
}) => {
  // figure out the enum => array value
  const currentStatus = mergeStatus[mergerStatus];
  const activeStep = mergerArray.indexOf(currentStatus);

  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<ColorlibConnector />}
    >
      {mergerArray.map((label, index) => (
        <Step key={label}>
          <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

const EditMergerInformation: React.FC<EditMergerInformationProps> = ({
  setIsEditing,
  taxStartYear,
  taxEndYear,
  effective,
  updateMergeOrganization,
  isLoading,
}) => {
  const { addGlobalMessage } = useGlobalContext();
  const [startTaxYear, setStartTaxYear] = useState(taxStartYear);
  const [endTaxYear, setEndTaxYear] = useState(taxEndYear);
  const [effectiveDate, setEffectiveDate] = useState(effective);

  useEffect(() => {
    if (!effective) {
      setEffectiveDate(format(new Date(), 'yyyy-MM-dd'));
    }
  }, [effective]);

  return (
    <Box width="1" mt={4} mb={4} ml={3} mr={3}>
      {/* these components will be used for the tax start year and end year */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            type="date"
            name="effectiveDate"
            fullWidth
            value={effectiveDate}
            onChange={(e) => {
              setEffectiveDate(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            name="taxYear"
            label="Starting Tax Year"
            fullWidth
            value={startTaxYear}
            onChange={(e) => {
              setStartTaxYear(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            name="taxYear"
            label="Ending Tax Year"
            fullWidth
            value={endTaxYear}
            onChange={(e) => {
              setEndTaxYear(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {isLoading && <LinearProgress color="secondary" />}
          <Box pt={3}>
            {/* Form Submit */}
            <Button
              type="submit"
              disabled={isLoading}
              variant="contained"
              color="primary"
              sx={{
                mr: 2,
              }}
              data-qa="step-submit-button"
              onClick={() => {
                updateMergeOrganization(
                  endTaxYear,
                  startTaxYear,
                  effectiveDate,
                );
              }}
            >
              Update Merger
            </Button>

            <Button
              disabled={isLoading}
              variant="outlined"
              sx={{
                mr: 2,
              }}
              data-qa="step-cancel-button"
              onClick={() => {
                setIsEditing(false);
              }}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

function ViewIndividualMerger() {
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(false as boolean);

  const [isEditing, setIsEditing] = useState(false as boolean);
  const [selectedOrg, setSelectedOrg] = useState(null as any);
  const [primaryMerge, setPrimaryMerge] = useState(null as any);
  const [mergingOrg, setMergingOrg] = useState(null as any);
  const queryParams = new URLSearchParams(useLocation().search);

  const [survivingMergeStatus, setSurvivingMergeStatus] = useState('' as any);
  const { addGlobalMessage } = useGlobalContext();

  const [mergingOrgCount, setMergingOrgCount] = useState(0);
  const [selectedOrgCount, setSelectedOrgCount] = useState(0);
  const [isMergerLoading, setIsMergerLoading] = useState(false);
  const [amendmentDescription, setAmendmentDescription] = useState('');
  const [amendmentNotificationType, setAmendmentNotificationType] =
    useState('');

  // grab queryparams from url to be used to fetch org data
  const survivingOrgId = queryParams.get('survivingOrg');
  const mergingOrgId = queryParams.get('mergingOrg');

  const theme = useTheme();
  const classes = {
    profileCard: {
      color: theme.palette.grey[300],
      marginTop: theme.spacing(2),
      padding: theme.spacing(4),
      background: theme.palette.primary.main,
      height: '85%',
    },
    moreInformation: {
      color: theme.palette.grey[300],
      paddingTop: theme.spacing(1),
      background: theme.palette.primary.main,
    },
  };

  // Fetch the org data for the current user's org
  async function selectOrgAndGoToProfile() {
    setIsLoading(true);
    await getOrg(survivingOrgId, user.token, user).then((res) => {
      setSelectedOrg(res.data);
      setIsLoading(false);
    });
  }

  // Fetch the org data for the current user's org
  async function mergingOrganizations() {
    setIsLoading(true);
    await getOrg(mergingOrgId, user.token, user).then((res) => {
      setMergingOrg(res.data);
      getMergingOrgCount(res.data.financialOrganizationId);
    });
  }

  async function getMergingOrgCount(orgId) {
    const data = {
      survivingOrgId: survivingOrgId,
      mergingOrgId: orgId,
    };
    await getAccountMergerCounts(data, user.token)
      .then((res) => {
        res.data.map((account) => {
          if (account.orgId === orgId) {
            setMergingOrgCount(account.accounts);
          } else if (account.orgId === parseInt(survivingOrgId)) {
            setSelectedOrgCount(account.accounts);
          }
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        addGlobalMessage(
          errorMessages(err) || 'Error fetching organization information',
        );
      });
  }

  const getIndividualSurvivingOrg = async () => {
    await getIndividualMerger({ parentOrgId: survivingOrgId }, user.token)
      .then((res) => {
        setIsMergerLoading(false);
        setPrimaryMerge(res.data[0]);
      })
      .catch((err) => {
        setIsLoading(false);
        addGlobalMessage(
          errorMessages(err) || 'Error fetching surviving merging information',
        );
      });
  };

  const updateMergeOrganization = async (
    endTaxYear,
    startTaxYear,
    effectiveDate,
  ) => {
    const data = {
      ...primaryMerge,
      taxYearStart: startTaxYear,
      taxYearEnd: endTaxYear,
      mergerEffectiveDate: effectiveDate,
    };

    setIsLoading(true);
    await updateMerger(survivingOrgId, primaryMerge.mergerId, data, user.token)
      .then((res) => {
        setIsEditing(false);
        getIndividualSurvivingOrg();
      })
      .catch((err) => {
        setIsLoading(false);
        addGlobalMessage(
          errorMessages(err) || 'Error updating merger information',
        );
      });
  };

  const createAmendment = async () => {
    const data = {
      amendmentNotificationType: amendmentNotificationType,
      mergerId: primaryMerge.mergerId,
      amendmentDescription: amendmentDescription,
    };

    setIsMergerLoading(true);
    await createAmendments(data, user.token)
      .then((res) => {
        setIsMergerLoading(false);
        addGlobalMessage('Amendment created successfully');
      })
      .catch((err) => {
        setIsMergerLoading(false);
        addGlobalMessage(errorMessages(err) || 'Error creating amendment');
      });
  };

  useEffect(() => {
    if (user.token) {
      setIsLoading(true);
      selectOrgAndGoToProfile();
      mergingOrganizations();

      // individual merger
      getIndividualSurvivingOrg();
    }
  }, [survivingOrgId, mergingOrgId]);

  useEffect(() => {
    if (primaryMerge) {
      setSurvivingMergeStatus(primaryMerge.mergerStatus);
    }
  }, [primaryMerge]);

  // progress merger status
  const progressMergerStatus = async () => {
    const currentStatus = mergeStatus[survivingMergeStatus];
    const activeStep = mergerArray.indexOf(currentStatus);
    const newStatus = mergerArray[activeStep + 1];

    if (newStatus === 'Joint Accounts') {
      setIsMergerLoading(true);
      await updateSharedAccounts(
        {
          parentOrgId: survivingOrgId,
          mergerId: primaryMerge.mergerId,
        },
        user.token,
      )
        .then((res) => {
          setSurvivingMergeStatus(newStatus);
          // individual merger
          getIndividualSurvivingOrg();
        })
        .catch((err) => {
          setIsMergerLoading(false);
          addGlobalMessage(
            errorMessages(err) || 'Error merging joint accounts',
          );
        });
      // move accounts
    } else if (newStatus === 'All Accounts') {
      setIsMergerLoading(true);
      await updateAccountOwners(
        {
          parentOrgId: survivingOrgId,
          mergerId: primaryMerge.mergerId,
        },
        user.token,
      )
        .then((res) => {
          setSurvivingMergeStatus(newStatus);
          // individual merger
          getIndividualSurvivingOrg();
        })
        .catch((err) => {
          setIsMergerLoading(false);
          addGlobalMessage(errorMessages(err) || 'Error merging all accounts');
        });
    }
  };

  return (
    <Layout>
      <PaperlessPage>
        <>
          <Typography data-qa="merge-header" variant="overline" gutterBottom>
            Merging Organizational
          </Typography>
          <Grid container spacing={3} wrap="wrap" mt={2}>
            <>
              {mergingOrg && (
                <Grid item xs={5} md={5}>
                  <Paper elevation={3} sx={classes.profileCard}>
                    <>
                      <Typography variant="overline" color="secondary">
                        Merging Organization Name
                      </Typography>

                      <Typography color="secondary" variant="h3" gutterBottom>
                        {mergingOrg.name}
                      </Typography>

                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={5}>
                          <Typography
                            variant="subtitle2"
                            color="secondary"
                            gutterBottom
                          >
                            Legal Name
                          </Typography>

                          <Typography variant="body1">
                            {mergingOrg.legalName}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Typography
                            variant="subtitle2"
                            color="secondary"
                            gutterBottom
                          >
                            Federal Tax ID
                          </Typography>

                          <Typography variant="body1">
                            <ObscuredTaxId
                              taxId={mergingOrg.federalTaxId}
                              format={TaxIdFormat.ein}
                            />
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <Typography
                            variant="subtitle2"
                            color="secondary"
                            gutterBottom
                          >
                            Org Number
                          </Typography>

                          <Typography variant="body1">
                            {mergingOrg.financialOrganizationId}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle2"
                            color="secondary"
                            gutterBottom
                          >
                            Number of Accounts
                          </Typography>

                          <Typography variant="body1">
                            {mergingOrgCount}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  </Paper>
                </Grid>
              )}
              {selectedOrg && mergingOrg && (
                <Grid
                  item
                  xs={2}
                  md={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ArrowForwardIcon
                    color="secondary"
                    style={{ fontSize: 48 }}
                  />
                </Grid>
              )}
              {isLoading && <LinearProgress color="secondary" />}
              {selectedOrg && (
                <Grid item xs={5} md={5}>
                  <Paper elevation={3} sx={classes.profileCard}>
                    <>
                      <Typography variant="overline" color="secondary">
                        Surviving Organization Name
                      </Typography>

                      <Typography color="secondary" variant="h3" gutterBottom>
                        {selectedOrg.name}
                      </Typography>

                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={5}>
                          <Typography
                            variant="subtitle2"
                            color="secondary"
                            gutterBottom
                          >
                            Legal Name
                          </Typography>

                          <Typography variant="body1">
                            {selectedOrg.legalName}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Typography
                            variant="subtitle2"
                            color="secondary"
                            gutterBottom
                          >
                            Federal Tax ID
                          </Typography>

                          <Typography variant="body1">
                            <ObscuredTaxId
                              taxId={selectedOrg.federalTaxId}
                              format={TaxIdFormat.ein}
                            />
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <Typography
                            variant="subtitle2"
                            color="secondary"
                            gutterBottom
                          >
                            Org Number
                          </Typography>

                          <Typography variant="body1">
                            {selectedOrg.financialOrganizationId}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle2"
                            color="secondary"
                            gutterBottom
                          >
                            Number of Accounts
                          </Typography>

                          <Typography variant="body1">
                            {selectedOrgCount}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  </Paper>
                </Grid>
              )}
              {primaryMerge && (
                <Grid item xs={12}>
                  {/* This card will provide additional information of the merge with two buttons to make additional merger calls */}
                  <Card elevation={3}>
                    <CardHeader title="Merger Information" />
                    <CardContent>
                      <Grid container spacing={3}>
                        {!isEditing ? (
                          <>
                            <Grid item xs={4} md={3}>
                              <Typography variant="overline" color="secondary">
                                Merge Effective Date
                              </Typography>
                              <Typography variant="body1">
                                {primaryMerge.mergerEffectiveDate
                                  ? format(
                                      parseISO(
                                        String(
                                          primaryMerge.mergerEffectiveDate,
                                        ),
                                      ),
                                      'MM/dd/yyyy',
                                    )
                                  : 'N/A'}
                              </Typography>
                            </Grid>
                            <Grid item xs={3} md={3}>
                              <Typography variant="overline" color="secondary">
                                Tax Year Start
                              </Typography>
                              <Typography variant="body1">
                                {primaryMerge.taxYearStart}
                              </Typography>
                            </Grid>
                            <Grid item xs={3} md={3}>
                              <Typography variant="overline" color="secondary">
                                Tax Year End
                              </Typography>
                              <Typography variant="body1">
                                {primaryMerge.taxYearEnd}
                              </Typography>
                            </Grid>
                            <Grid item xs={3} md={3}>
                              <Button
                                style={{ marginTop: '1rem' }}
                                aria-label="Edit Merger information"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setIsEditing(!isEditing);
                                }}
                                startIcon={<EditIcon />}
                              >
                                Edit Merger
                              </Button>
                            </Grid>
                          </>
                        ) : (
                          <EditMergerInformation
                            setIsEditing={setIsEditing}
                            taxStartYear={primaryMerge.taxYearStart}
                            taxEndYear={primaryMerge.taxYearEnd}
                            effective={
                              primaryMerge.mergerEffectiveDate
                                ? format(
                                    parseISO(
                                      String(primaryMerge.mergerEffectiveDate),
                                    ),
                                    'MM/dd/yyyy',
                                  )
                                : format(new Date(), 'yyyy-MM-dd')
                            }
                            isLoading={isLoading}
                            updateMergeOrganization={updateMergeOrganization}
                          />
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              )}
              {primaryMerge && (
                <Grid item xs={12}>
                  {/* This card will provide additional information of the merge with two buttons to make additional merger calls */}
                  <Card elevation={3}>
                    <CardHeader title="Current Merger Progress" />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item xs={12} mt={3}>
                          <MergerStatusStepper
                            mergerStatus={survivingMergeStatus}
                          />
                        </Grid>
                        {isMergerLoading && (
                          <Grid item xs={12}>
                            <LinearProgress color="secondary" />
                          </Grid>
                        )}
                        {primaryMerge.mergerStatus ===
                        MergingOrgStatus.allAccounts ? (
                          <Grid container spacing={3} pt={3} pl={2} pr={2}>
                            <Grid item xs={12} md={6}>
                              <FormControl fullWidth>
                                <InputLabel id="Amendment-Notification-Type">
                                  Amendment Notification Type
                                </InputLabel>
                                <Select
                                  labelId="Amendment-Notification-Type"
                                  id="amendment-notification-type-select"
                                  value={amendmentNotificationType}
                                  label="Amendment Notification Type"
                                  onChange={(e) => {
                                    setAmendmentNotificationType(
                                      e.target.value,
                                    );
                                  }}
                                >
                                  {selectMergerAmendmentNotificationType.map(
                                    (option) => (
                                      <MenuItem
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {' '}
                                        {option.label}
                                      </MenuItem>
                                    ),
                                  )}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="Amendment Description"
                                value={amendmentDescription}
                                onChange={(e) => {
                                  setAmendmentDescription(e.target.value);
                                }}
                              />
                            </Grid>
                          </Grid>
                        ) : null}
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          justifyContent="center"
                        >
                          <Box pt={1}>
                            {/* Form Submit */}
                            <Button
                              style={{ marginTop: '1rem' }}
                              aria-label="Next Step"
                              variant="contained"
                              color="primary"
                              disabled={isMergerLoading}
                              onClick={() => {
                                progressMergerStatus();
                              }}
                            >
                              Move Accounts
                            </Button>

                            {primaryMerge.mergerStatus ===
                            MergingOrgStatus.allAccounts ? (
                              <Button
                                style={{
                                  marginTop: '1rem',
                                  marginLeft: '1rem',
                                  paddingLeft: '1rem',
                                  paddingRight: '1rem',
                                }}
                                disabled={isLoading}
                                variant="outlined"
                                sx={{
                                  mr: 2,
                                }}
                                data-qa="step-cancel-button"
                                onClick={createAmendment}
                              >
                                Create Amendment
                              </Button>
                            ) : null}
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </>
          </Grid>
        </>
      </PaperlessPage>
    </Layout>
  );
}
export default ViewIndividualMerger;
