/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import { AccountType } from '../../../api/AccountApi.d';
import { DepositType } from '../../../api/ContributionApi.d';
import {
  newAccountDepositHelpTxt,
  newAccountModalHelpTxt,
} from './resource.txt';

export function getModalText(
  accountType?: AccountType,
  depositType?: DepositType,
  dropDown?: string
) {
  if (accountType === AccountType.esa) {
    if (depositType === DepositType.transfer) {
      return newAccountModalHelpTxt.esa.transfer;
    }

    if (depositType === DepositType.rollover) {
      return newAccountModalHelpTxt.esa.rollover;
    }
  }

  if (accountType === AccountType.hsa) {
    if (depositType === DepositType.qualifiedHSAlbl) {
      if (dropDown === 'oneTime') {
        return newAccountModalHelpTxt.hsa.oneTime;
      }
      if (dropDown === 'ongoingSep') {
        return newAccountModalHelpTxt.hsa.ongoingSepOrSimple;
      }
    }
    if (depositType === DepositType.rollover) {
      return newAccountDepositHelpTxt.hsa.qlHsaFundingDist;
    }
  }

  if (depositType === DepositType.repayment) {
    if (dropDown === 'birthOrAdoption') {
      return newAccountModalHelpTxt.repayment.birthOrAdoption;
    }
    if (dropDown === 'reservistDistribution') {
      return newAccountModalHelpTxt.repayment.reservistDistribution;
    }
    if(dropDown === 'abuseVictim') {
      return newAccountModalHelpTxt.repayment.abuseVictim;
    }
    if(dropDown === 'emergencyPersonalExpense') {
      return newAccountModalHelpTxt.repayment.emergencyPersonalExpense;
    }
  }

  if (depositType === DepositType.esalbl) {
    return newAccountModalHelpTxt.esa.transfer;
  }

  if (depositType === DepositType.hsalbl) {
    return newAccountModalHelpTxt.hsa.oncePerLifeTime;
  }
}
