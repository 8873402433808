import { FrequencyValues } from '../components/form/distribution/Frequency.d';

export interface WithholdingNotificationsResponse {
  data: Array<any>;
  errorMessage: any;
}

export interface WithholdingNotificationResponse {
  withholdingNotification: WithholdingNotification;
  accountOwner: AccountOwner;
  account: Account;
}

export interface WithholdingNotification {
  withholdingNotificationId: string;
  financialOrganizationId: string;
  accountOwnerId: string;
  accountId: string;
  recurringDistributionFrequency: FrequencyValues;
  federalWithholdingPercent: string;
  withholdingState: string;
  stateWithholdingPercent: string;
  additionalStateWithholding: string;
  withholdingNotificationStatus: WithholdingNotificationStatus;
  withholdingNotificationType: WithholdingNotificationType;
  recordCreated: string;
  notificationSent: string;
}

export enum WithholdingNotificationStatus {
  pending = 'PENDING',
  sent = 'NOTIFICATION_SENT',
  draft = 'DRAFT',
}

export enum WithholdingNotificationType {
  june = 'JUNE',
  december = 'DECEMBER',
}
