/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Grid, } from '@mui/material';
import { Formik, Form, Field } from 'formik';

import StepButtonBar from '../../steps/StepButtonBar';
import SiraTextField from '../SiraTextField';

import { useUser } from '../../../auth/useUser';
import { UserRole } from '../../../api/UserApi.d';
import { CoreIntegrationSettings } from '../../organizationProfile/OrganizationCoreIntegrationSettings';

export const FINANCIAL_CORE_SETTINGS_INT: CoreIntegrationSettings = {
    id: 0,
    financialOrganizationId: 0,
    clientNumber: '',
    clientSystemUserNumber: 0,
    clientLogin: '',
    shareTransactionSourceCode: 0,
    deviceName: '',
    deviceNumber: 0,
    coreUrl: '',
};


export interface FinancialOrgCoreIntegrationSettingsProps {
  initialValues: CoreIntegrationSettings;
  onSubmit?: Function;
  onReset?: Function;
  onCancel?: Function;
  submitName?: string;
  resetName?: string;
}

const FinancialOrgCoreIntegrationSettings = ({
  initialValues,
  onSubmit,
  onReset,
  onCancel,
  submitName,
  resetName,
}: FinancialOrgCoreIntegrationSettingsProps) => {
  const { user } = useUser();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      onReset={() => {
        if (onReset) onReset();
      }}
      enableReinitialize
    >
      {({ isSubmitting, values, setFieldValue }) => {

        return (
          <Form>
            <Grid container spacing={3}>

            <Grid item xs={12} sm={6}>
                    <SiraTextField name="clientLogin" label="Client Login" />
                  </Grid>                  <Grid item xs={12} sm={6}>
                    <SiraTextField name="clientNumber" label="Client Number" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SiraTextField name="clientSystemUserNumber" label="Client System User Number" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SiraTextField name="coreUrl" label="Core Url" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SiraTextField name="deviceName" label="Device Name" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SiraTextField name="deviceNumber" label="Device Number" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SiraTextField name="shareTransactionSourceCode" label="Share Transaction Source Code" />
                  </Grid>
            </Grid>

            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              resetName={resetName}
              onCancel={onCancel}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default FinancialOrgCoreIntegrationSettings;
