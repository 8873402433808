import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import MergeIcon from '@mui/icons-material/Merge';
import VisibilityIcon from '@mui/icons-material/Visibility';

const CreateMergeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>

        <g transform="matrix(.337698 0 0 0.290204 1.878451 4.58563)">
          <path
            d="M42.3,44c-5.6-2.7-9.6-7.5-11.6-13-.8-2-1.3-4.3-1.5-6.3v-3.5h10.8c.8,0,1.4-.9.8-1.8L26.6,2.4c-.5-.6-1.6-.6-2,0l-13.8,17c-.5.6,0,1.8.8,1.8h10.9c0,1.5,0,3.4,0,3.4v.1v0c-.3,2.1-.8,4.4-1.5,6.3-2,5.5-6,10.3-11.6,13-.8.3-1.1,1.3-.8,2l1.3,3.1c.4.8,1.3,1.1,2.1.6c6-2.9,10.8-7.5,13.7-13c3,5.5,7.7,10.1,13.8,13c.8.4,1.8.3,2.1-.6L42.9,46c.6-.7.2-1.6-.6-2Z"
            transform="matrix(.917439-.001511 0.001617 0.98182-.074622 0.06587)"
          />
          <path
            d="M6.718467,0C3.007564,0,0,4.032625,0,9.00831s3.007564,9.008312,6.718467,9.008312s6.718464-4.032628,6.718464-9.008312-3.007562-9.00831-6.718464-9.00831Zm3.918229,9.757829c0,.415228-.249319.749519-.558997.749519h-2.238614v3.005115c0,.415228-.249317.749521-.558997.749521h-1.120618c-.309681,0-.558997-.337813-.558997-.749521v-3.001597h-2.241239c-.309678,0-.558997-.337811-.558997-.749519v-1.502558c0-.415226.249319-.749519.558997-.749519h2.238614v-3.005115c0-.415226.249319-.749519.558997-.749519h1.120619c.309678,0,.558997.337813.558997.749519v3.001597h2.241238c.309678,0,.558997.337813.558997.749519v1.502558Z"
            transform="matrix(1.101898-.000237 0.000206 0.956298 36.042703 38.754148)"
          />
        </g>
    </SvgIcon>
  );
};

export default CreateMergeIcon;
