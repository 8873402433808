import React, { useEffect, useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import {
  addYears,
  endOfYear,
  formatISO,
  isValid,
  isWithinInterval,
  parseISO,
  startOfYear,
  format,
} from 'date-fns';
import { Cell, Pie, PieChart } from 'recharts';
import {
  Grid,
  Box,
  Typography,
  Divider,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  DataGrid,
  GridCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';

import { useUser } from '../auth/useUser';
import {
  useGlobalContext,
  usePaginationContext,
} from '../auth/useGlobalContext';
import {
  Account,
  AccountStatus,
  AccountSupportedTypestring,
  AccountType,
} from '../api/AccountApi.d';
import { ConstantsMappingKey } from '../api/SharedTextApi.d';
import { AccountOwner } from '../api/AccountOwnerApi.d';
import { determineAgeGroup, globalPaginationOptions } from '../app.constants';
import { dateValidation } from '../components/form/SiraDateField';
import SiraDateRangeField from '../components/form/SiraDateRangeField';
import Layout from '../components/Layout';
import FormPaper from '../components/FormPaper';
import { dateValueFormatter } from '../utils/DataGrid.utils';
import SiraNoRowsOverlay from '../components/SiraNoRowsOverlay';
import DataGridExportToolbar from '../components/DataGridExportToolbar';
import ObscuredTaxId, { TaxIdFormat } from '../components/ObscuredTaxId';
import ResponsivePercentBar, {
  ResponsivePercentBarProps,
} from '../components/reports/ResponsivePercentBar';
import { AccountMemberValue } from '../api/OrganizationApi.d';
import {
  getAccountSummaryDashboards,
  getDashboardAccounts,
} from '../api/OrganizationApi';
import { errorMessages } from '../utils/errorhandling.utils';

interface AccountSummaryCounts {
  accountsOpened: number;
  accountsClosed: number;
  totalOpenAccounts: number;
  tradIrasUnder73: number;
  tradIras73OrOlder: number;
  rothFiveYearTestIsTrue: number;
  rothFiveYearTestIsFalse: number;
  sepUnder73: number;
  sep73OrOlder: number;
  simpleUnder73: number;
  simple73OrOlder: number;
  inheritedRoth5YearTrue: number;
  inheritedRoth5YearFalse: number;
  coverdalUnder18: number;
  coverdal18OrOver: number;
  coverdal30OrOver: number;
  openHsas: number;
  openInherited: number;
}

interface AdditionalStatsProps {
  accountType: AccountType; // Account type for which to look up the stats component
  matchingOpenAccounts: Array<Account & AccountOwner>;
  summaryCounts: AccountSummaryCounts;
}

interface AccountSummaryForm {
  startDate: string;
  endDate: string;
}

interface AdditionalStatsChartProps {
  stats?: Array<ResponsivePercentBarProps>;
  accountType: AccountType | string;
  isOver18?: number;
  isOver30?: number;
  isUnder18?: number;
  isUnder73?: number;
  isOver73?: number;
  fiveYearMet?: number;
  fiveYearUnmet?: number;
  allAccounts?: number;
}

enum AgeDeterminationLegend {
  isOver18 = 'Owners 18 or over',
  isUnder18 = 'Owners under 18',
  isUnder73 = 'Owners under 73',
  isOver73 = 'Owners 73 or over',
  isOver30 = 'Owners 30 or over',
  fiveYearMet = 'Five year rule met',
  fiveYearUnmet = 'Five year rule not met',
}

const ACCOUNT_SUMMARY_INIT: AccountSummaryForm = {
  startDate: formatISO(startOfYear(new Date()), { representation: 'date' }),
  endDate: formatISO(endOfYear(new Date()), { representation: 'date' }),
};

const ACCOUNT_SUMMARY_SCHEMA = yup.object().shape(
  {
    startDate: yup
      .string()

      .when('endDate', (endDate, schema) =>
        endDate
          ? dateValidation(undefined, parseISO(endDate)).label('Start Date')
          : schema,
      ),
    endDate: yup
      .string()

      .when('startDate', (startDate, schema) =>
        startDate
          ? dateValidation(parseISO(startDate), addYears(new Date(), 10)).label(
              'End Date',
            )
          : schema,
      ),
  },
  [['startDate', 'endDate']],
);

function AdditionalStatsChart(props: AdditionalStatsChartProps) {
  const {
    stats = [],
    accountType = '',
    isOver18,
    isOver30,
    isOver73,
    isUnder18,
    isUnder73,
    fiveYearMet,
    fiveYearUnmet,
    allAccounts,
  } = props;
  const { setGlobalPageSize } = usePaginationContext();
  const navigate = useNavigate();
  const {
    getAppConstant,
    organization: { accountNumberValue },
  } = useGlobalContext();
  const headerAccountName =
    accountNumberValue === AccountMemberValue.accountNumber
      ? 'Account #'
      : 'Member #';

  const goToAccountProfile = (result: Account & AccountOwner): void => {
    const { accountOwnerId = '' } = result || {};

    if (accountOwnerId) {
      navigate(`/accountOwner/${accountOwnerId}`);
    }
  };

  const openDateColumn = {
    field: 'openDate',
    headerName: 'Date Opened',
    width: 126,
    valueFormatter: dateValueFormatter,
  };

  const columns = [
    {
      field: 'fullName',
      headerName: 'Name',
      width: 175,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            data-qa="account-search-result-link"
            onClick={() => {
              goToAccountProfile(row);
            }}
            sx={{
              justifyContent: 'flex-start' as any,
              width: '100%',
              textDecoration: 'none' as any,
              textTransform: 'none' as any,
            }}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'taxpayerIdNumber',
      headerName: 'SSN/ID',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ssn} />
        );
      },
    },
    {
      field: 'dateOfBirth',
      headerName: 'Birth Date',
      width: 126,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      width: 156,
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        const typeId = value.toString();

        return getAppConstant(ConstantsMappingKey.accountType, typeId);
      },
    },
    {
      field: 'accountNumber',
      headerName: headerAccountName,
      width: 156,
      sortable: false,
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      {!stats.length ? (
        <Grid item xs={12}>
          <DataGrid
            components={{
              NoRowsOverlay: SiraNoRowsOverlay,
              Toolbar: DataGridExportToolbar,
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={globalPaginationOptions}
            onPaginationModelChange={setGlobalPageSize}
            disableColumnMenu
            autoHeight
            columns={[openDateColumn, ...columns]}
            rows={[]}
          />
        </Grid>
      ) : (
        stats.map((row) => (
          <Grid item xs={12} key={row.legend}>
            <Accordion key={accountType} elevation={2}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid key={row.legend} item xs={12}>
                  <ResponsivePercentBar {...row} isNested />
                </Grid>
              </AccordionSummary>

              <AccordionDetails>
                <DataGrid
                  components={{
                    NoRowsOverlay: SiraNoRowsOverlay,
                    Toolbar: DataGridExportToolbar,
                  }}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  pageSizeOptions={globalPaginationOptions}
                  onPaginationModelChange={setGlobalPageSize}
                  disableColumnMenu
                  autoHeight
                  columns={[openDateColumn, ...columns]}
                  rows={[]}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))
      )}
    </Grid>
  );
}

// Just add stat components here and they'll show up in the accordion when they match the account type
function AdditionalStats(props: AdditionalStatsProps) {
  const { accountType = '', matchingOpenAccounts = [], summaryCounts } = props;

  const EsaStats = () => {
    const isUnder18 = summaryCounts.coverdalUnder18;
    const isOver18 = summaryCounts.coverdal18OrOver;

    const isOver30 = summaryCounts.coverdal30OrOver;

    const openAccounts =
      summaryCounts.coverdalUnder18 +
      summaryCounts.coverdal18OrOver +
      summaryCounts.coverdal30OrOver;

    const esaStats = [
      {
        legend: 'Owners under 18',
        quantity: isUnder18,
        percent: (isUnder18 / openAccounts) * 100,
      },
      {
        legend: 'Owners 18 or over',
        quantity: isOver18,
        percent: (isOver18 / openAccounts) * 100,
      },
      {
        legend: 'Owners 30 or over',
        quantity: isOver30,
        percent: (isOver30 / openAccounts) * 100,
      },
    ];

    return (
      <AdditionalStatsChart
        stats={esaStats}
        accountType={accountType}
        isOver18={isOver18}
        isUnder18={isUnder18}
        isOver30={isOver30}
      />
    );
  };

  const IraStats = () => {
    let isUnder73;
    let isOver73;
    let iraStats = [];
    const openAccounts =
      summaryCounts.tradIras73OrOlder + summaryCounts.tradIrasUnder73;

    switch (accountType) {
      case AccountType.traditionalIra:
        isUnder73 = summaryCounts.tradIrasUnder73;
        isOver73 = summaryCounts.tradIras73OrOlder;

        iraStats = [
          {
            legend: 'Owners under 73',
            quantity: summaryCounts.tradIrasUnder73,
            percent: (isUnder73 / openAccounts) * 100,
          },
          {
            legend: 'Owners 73 or over',
            quantity: summaryCounts.tradIras73OrOlder,
            percent: (isOver73 / openAccounts) * 100,
          },
        ];
        break;
      case AccountType.traditionalSep:
        isUnder73 = summaryCounts.sepUnder73;
        isOver73 = summaryCounts.sep73OrOlder;
        iraStats = [
          {
            legend: 'Owners under 73',
            quantity: summaryCounts.sepUnder73,
            percent: (isUnder73 / openAccounts) * 100,
          },
          {
            legend: 'Owners 73 or over',
            quantity: summaryCounts.sep73OrOlder,
            percent: (isOver73 / openAccounts) * 100,
          },
        ];
        break;
      case AccountType.simpleIra:
        isUnder73 = summaryCounts.simpleUnder73;
        isOver73 = summaryCounts.simple73OrOlder;
        iraStats = [
          {
            legend: 'Owners under 73',
            quantity: summaryCounts.simpleUnder73,
            percent: (isUnder73 / openAccounts) * 100,
          },
          {
            legend: 'Owners 73 or over',
            quantity: summaryCounts.simple73OrOlder,
            percent: (isOver73 / openAccounts) * 100,
          },
        ];
        break;
      default:
        break;
    }
    return (
      <AdditionalStatsChart
        stats={iraStats}
        accountType={accountType}
        isUnder73={isUnder73}
        isOver73={isOver73}
      />
    );
  };

  const RothStats = () => {
    let fiveYearRuleMet;
    let fiveYearRuleUnmet;
    let rothStats = [];
    const openAccounts =
      summaryCounts.rothFiveYearTestIsFalse +
      summaryCounts.rothFiveYearTestIsTrue;

    switch (accountType) {
      case AccountType.rothIra:
        fiveYearRuleMet = summaryCounts.rothFiveYearTestIsTrue;
        fiveYearRuleUnmet = summaryCounts.rothFiveYearTestIsFalse;
        rothStats = [
          {
            legend: 'Five year rule met',
            quantity: fiveYearRuleMet,
            percent: (fiveYearRuleMet / openAccounts) * 100,
          },
          {
            legend: 'Five year rule not met',
            quantity: fiveYearRuleUnmet,
            percent: (fiveYearRuleUnmet / openAccounts) * 100,
          },
        ];
        break;
      case AccountType.inheritedRoth:
        fiveYearRuleMet = summaryCounts.inheritedRoth5YearTrue;
        fiveYearRuleUnmet = summaryCounts.inheritedRoth5YearFalse;
        rothStats = [
          {
            legend: 'Five year rule met',
            quantity: fiveYearRuleMet,
            percent: (fiveYearRuleMet / openAccounts) * 100,
          },
          {
            legend: 'Five year rule not met',
            quantity: fiveYearRuleUnmet,
            percent: (fiveYearRuleUnmet / openAccounts) * 100,
          },
        ];
        break;
      default:
        break;
    }
    return (
      <AdditionalStatsChart
        stats={rothStats}
        accountType={accountType}
        fiveYearMet={fiveYearRuleMet}
        fiveYearUnmet={fiveYearRuleUnmet}
      />
    );
  };

  const AccountList = () => {
    return (
      <AdditionalStatsChart
        accountType={accountType}
        allAccounts={summaryCounts.totalOpenAccounts}
      />
    );
  };

  const componentMap: { [key: string]: Function } = {
    [AccountType.esa]: EsaStats,
    [AccountType.traditionalIra]: IraStats,
    [AccountType.traditionalSep]: IraStats,
    [AccountType.simpleIra]: IraStats,
    [AccountType.rothIra]: RothStats,
    [AccountType.inheritedRoth]: RothStats,
    [AccountType.inheritedTraditional]: AccountList,
    [AccountType.hsa]: AccountList,
  };

  return componentMap[accountType] ? componentMap[accountType]() : null;
}

function AccountsSummaryRevamp() {
  let isMounted = true;
  const theme = useTheme();
  const navigate = useNavigate();
  const { setGlobalPageSize } = usePaginationContext();
  const {
    getAppConstant,
    organization: { legalName = '', accountNumberValue },
    addGlobalMessage,
  } = useGlobalContext();
  const headerAccountName =
    accountNumberValue === AccountMemberValue.accountNumber
      ? 'Account #'
      : 'Member #';

  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [dateFilters, setDateFilters] = useState({
    startDate: new Date(),
    endDate: new Date(),
  } as any);
  const [orgAccounts, setOrgAccounts] = useState(
    [] as Array<Account & AccountOwner>,
  );
  const accountTypesSupported =
    AccountSupportedTypestring as Array<AccountType>;
  const [pieData, setPieData] = useState([] as any);
  const [accountSummaryCounts, setAccountSummaryCounts] = useState(
    {} as AccountSummaryCounts,
  );


  const goToAccountProfile = (result: Account & AccountOwner): void => {
    const { accountOwnerId = '' } = result || {};

    if (accountOwnerId) {
      navigate(`/accountOwner/${accountOwnerId}`);
    }
  };

  // All open accounts regardless of date filters
  const openAccounts = useMemo(
    () =>
      orgAccounts.filter(
        ({ accountStatus }) => accountStatus === AccountStatus.open,
      ),
    [orgAccounts],
  );

  async function fetchSummaryAccounts() {
    setIsLoading(true);
    const { startDate, endDate } = dateFilters;

    const formattedStartDate = format(startDate, 'yyyy-MM-dd');
    const formattedEndDate = format(endDate, 'yyyy-MM-dd');


    await getAccountSummaryDashboards(
      {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      },
      user.organizationId,
      user.token,
    )
      .then(({ data }) => {
        if (isMounted) {
          // Pie chart data
          setPieData([
            {
              name: 'Opened',
              value: data.accountsOpened,
              color: theme.palette.secondary.light,
            },
            {
              name: 'Closed',
              value: data.accountsClosed,
              color: theme.palette.grey[600],
            },
          ]);

          setAccountSummaryCounts(data);
        }
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Error fetching organization accounts',
        );
      });

    if (isMounted) {
      setIsLoading(false);
    }
  }

  const openDateColumn = {
    field: 'openDate',
    headerName: 'Date Opened',
    width: 126,
    valueFormatter: dateValueFormatter,
  };

  const closedDateColumn = {
    field: 'closedDate',
    headerName: 'Date Closed',
    width: 126,
    valueFormatter: dateValueFormatter,
  };

  const columns = [
    {
      field: 'fullName',
      headerName: 'Name',
      width: 175,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            data-qa="account-search-result-link"
            onClick={() => {
              goToAccountProfile(row);
            }}
            sx={{
              justifyContent: 'flex-start' as any,
              width: '100%',
              textDecoration: 'none' as any,
              textTransform: 'none' as any,
            }}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'taxpayerIdNumber',
      headerName: 'SSN/ID',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ssn} />
        );
      },
    },
    {
      field: 'dateOfBirth',
      headerName: 'Birth Date',
      width: 126,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      width: 156,
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        const typeId = value.toString();

        return getAppConstant(ConstantsMappingKey.accountType, typeId);
      },
    },
    {
      field: 'accountNumber',
      headerName: headerAccountName,
      width: 156,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (user.token && user.organizationId) {
      fetchSummaryAccounts();
    }

    return () => {
      isMounted = false;
    };
  }, [user.organizationId, user.token, dateFilters]);

  // we will return the total of accounts based on the type
  const returnTotals = (accountType: AccountType) => {
    switch (accountType) {
      case AccountType.traditionalIra:
        const totalTradIras =
          accountSummaryCounts.tradIrasUnder73 +
          accountSummaryCounts.tradIras73OrOlder;
        return totalTradIras;
      case AccountType.rothIra:
        const totalRoths =
          accountSummaryCounts.rothFiveYearTestIsTrue +
          accountSummaryCounts.rothFiveYearTestIsFalse;
        return totalRoths;
      case AccountType.traditionalSep:
        const totalSep =
          accountSummaryCounts.sepUnder73 + accountSummaryCounts.sep73OrOlder;
        return totalSep;
      case AccountType.simpleIra:
        const totalSimple =
          accountSummaryCounts.simpleUnder73 +
          accountSummaryCounts.simple73OrOlder;
        return totalSimple;
      case AccountType.inheritedRoth:
        const totalInheritedRoth =
          accountSummaryCounts.inheritedRoth5YearTrue +
          accountSummaryCounts.inheritedRoth5YearFalse;
        return totalInheritedRoth;
      case AccountType.hsa:
        return accountSummaryCounts.openHsas;
      case AccountType.esa:
        const totalCoverdal =
          accountSummaryCounts.coverdalUnder18 +
          accountSummaryCounts.coverdal18OrOver +
          accountSummaryCounts.coverdal30OrOver;
        return totalCoverdal;
      case AccountType.inheritedTraditional:
        return accountSummaryCounts.openInherited;
      default:
    }
  };

  // we will return the percentage of accounts based on the type divided by open accounts * 100
  const returnPercent = (accountType: AccountType) => {
    const openAccounts = accountSummaryCounts.totalOpenAccounts;
    switch (accountType) {
      case AccountType.traditionalIra:
        const totalTradIras =
          accountSummaryCounts.tradIrasUnder73 +
          accountSummaryCounts.tradIras73OrOlder;
        return (totalTradIras / openAccounts) * 100;
      case AccountType.rothIra:
        const totalRoths =
          accountSummaryCounts.rothFiveYearTestIsTrue +
          accountSummaryCounts.rothFiveYearTestIsFalse;
        return (totalRoths / openAccounts) * 100;
      case AccountType.traditionalSep:
        const totalSep =
          accountSummaryCounts.sepUnder73 + accountSummaryCounts.sep73OrOlder;
        return (totalSep / openAccounts) * 100;
      case AccountType.simpleIra:
        const totalSimple =
          accountSummaryCounts.simpleUnder73 +
          accountSummaryCounts.simple73OrOlder;
        return (totalSimple / openAccounts) * 100;
      case AccountType.inheritedRoth:
        const totalInheritedRoth =
          accountSummaryCounts.inheritedRoth5YearTrue +
          accountSummaryCounts.inheritedRoth5YearFalse;
        return (totalInheritedRoth / openAccounts) * 100;
      case AccountType.hsa:
        return (accountSummaryCounts.openHsas / openAccounts) * 100;
      case AccountType.esa:
        const totalCoverdal =
          accountSummaryCounts.coverdalUnder18 +
          accountSummaryCounts.coverdal18OrOver +
          accountSummaryCounts.coverdal30OrOver;
        return (totalCoverdal / openAccounts) * 100;
      case AccountType.inheritedTraditional:
        return (accountSummaryCounts.openInherited / openAccounts) * 100;
      default:
    }
  };
  return (
    <Layout>
      <FormPaper>
        <>
          <Typography variant="overline" gutterBottom>
            Reports and Statistics
          </Typography>

          <Typography color="secondary" variant="h1">
            Accounts Summary Revamp
          </Typography>

          <Typography gutterBottom>{legalName}</Typography>

          <Grid item xs={12} mt={5}>
            {!user.organizationId ? (
              <Box p={2} pt={5} pb={5}>
                <Alert severity="warning">
                  Select an organization to see account summary
                </Alert>
              </Box>
            ) : (
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <Typography variant="h5" gutterBottom>
                        Accounts Activity
                      </Typography>
                    </Grid>

                    {/* Date Range */}
                    <Grid item xs={12} md={7}>
                      <Formik
                        initialValues={ACCOUNT_SUMMARY_INIT}
                        onSubmit={(values: any) => {
                          const startDate = parseISO(values.startDate);
                          const endDate = parseISO(values.endDate);

                          if (isValid(startDate) && isValid(endDate)) {
                            setDateFilters({
                              startDate,
                              endDate,
                            });
                          } else {
                            console.error('Invalid date values:', values);
                          }
                        }}
                        validationSchema={ACCOUNT_SUMMARY_SCHEMA}
                        enableReinitialize
                      >
                        {({ values, submitForm }) => {
                          // Submit the form whenever the values change
                          useEffect(() => {
                            submitForm();
                          }, [values]);

                          return (
                            <Form>
                              <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                pt={1}
                                pb={2}
                              >
                                <SiraDateRangeField />
                              </Grid>
                            </Form>
                          );
                        }}
                      </Formik>
                    </Grid>

                    {/* Pie Chart */}
                    <Grid
                      item
                      xs={12}
                      md={5}
                      sx={{
                        filter: isLoading ? 'blur(5px)' : '',
                        transition: 'filter 0.33s ease-in-out',
                      }}
                    >
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        wrap="nowrap"
                      >
                        <Grid item>
                          <PieChart width={140} height={140}>
                            <Pie
                              dataKey="value"
                              isAnimationActive={false}
                              data={pieData}
                              cx="50%"
                              cy="50%"
                              outerRadius={70}
                            >
                              {pieData &&
                                pieData.map(({ value, color }) => (
                                  <Cell key={value} fill={color} />
                                ))}
                            </Pie>
                          </PieChart>
                        </Grid>

                        <Grid item xs={12}>
                          {pieData.map(({ name, value, color }) => (
                            <Typography key={name} variant="body2" gutterBottom>
                              <Box
                                component="span"
                                mr={1}
                                display="inline-block"
                                width={10}
                                height={10}
                                bgcolor={color}
                              />
                              {name}: {value}
                            </Typography>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Open Account Details */}
                <Grid
                  item
                  xs={12}
                  sx={{
                    filter: isLoading ? 'blur(5px)' : '',
                    transition: 'filter 0.33s ease-in-out',
                  }}
                >
                  <Accordion elevation={2} disabled={isLoading}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant="body2" fontWeight="bold">
                            {accountSummaryCounts.accountsOpened} Accounts were
                            Opened
                          </Typography>
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          <Typography variant="caption">View all</Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <DataGrid
                        components={{
                          NoRowsOverlay: SiraNoRowsOverlay,
                          Toolbar: DataGridExportToolbar,
                        }}
                        initialState={{
                          pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        pageSizeOptions={globalPaginationOptions}
                        onPaginationModelChange={setGlobalPageSize}
                        disableColumnMenu
                        autoHeight
                        columns={[openDateColumn, ...columns]}
                        rows={[]}
                      />
                    </AccordionDetails>
                  </Accordion>

                  {/* Closed Account Details */}
                  <Accordion elevation={2} disabled={isLoading}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant="body2" fontWeight="bold">
                            {accountSummaryCounts.accountsClosed} Accounts were
                            Closed
                          </Typography>
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          <Typography variant="caption">View all</Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <DataGrid
                        components={{
                          NoRowsOverlay: SiraNoRowsOverlay,
                          Toolbar: DataGridExportToolbar,
                        }}
                        initialState={{
                          pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        pageSizeOptions={globalPaginationOptions}
                        onPaginationModelChange={setGlobalPageSize}
                        disableColumnMenu
                        autoHeight
                        columns={[closedDateColumn, ...columns]}
                        rows={[]}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                {/* Open Accounts By Type */}
                <Grid
                  item
                  xs={12}
                  sx={{
                    filter: isLoading ? 'blur(5px)' : '',
                    transition: 'filter 0.33s ease-in-out',
                  }}
                >
                  <Typography variant="h5">
                    {accountSummaryCounts.totalOpenAccounts} Current Open Accounts
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    filter: isLoading ? 'blur(5px)' : '',
                    transition: 'filter 0.33s ease-in-out',
                  }}
                >
                  {accountTypesSupported.map((accountTypeToMatch) => {
                    // Open accounts that match the current datum's type
                    const matchingOpenAccounts =
                      returnTotals(accountTypeToMatch);
                    // Percent to display and use for the bar chart width
                    const matchingPercent = returnPercent(accountTypeToMatch);

                    return (
                      <Accordion
                        key={accountTypeToMatch}
                        elevation={2}
                        disabled={isLoading}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <ResponsivePercentBar
                            legend={getAppConstant(
                              ConstantsMappingKey.accountType,
                              accountTypeToMatch,
                            )}
                            quantity={matchingOpenAccounts}
                            percent={matchingPercent}
                          />
                        </AccordionSummary>

                        <AccordionDetails>
                          <AdditionalStats
                            key={accountTypeToMatch}
                            accountType={accountTypeToMatch}
                            matchingOpenAccounts={[]}
                            summaryCounts={accountSummaryCounts}
                          />
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </Grid>
              </Grid>
            )}
          </Grid>
        </>
      </FormPaper>
    </Layout>
  );
}

export default AccountsSummaryRevamp;
