import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

import { getHubspotChatToken } from '../../api/ThirdPartyApi';
import { HubspotTokenRequestParams } from '../../api/ThirdPartyApi.d';
import { useUser } from '../../auth/useUser';
import { useGlobalContext } from '../../auth/useGlobalContext';
import { UserRole } from '../../api/UserApi.d';

export function HubSpotLiveChat() {
  const { user: superiorUser } = useUser();
  const { user } = useAuth0();
  const { organization } = useGlobalContext();
  // Ensure the script only gets appended once per session
  // const [isHubspotScriptLoaded, setIsHubspotScriptLoaded] = useState(
  //   false as boolean
  // );

  // Config and inject the HubSpot chat widget into DOM
  async function getTokenAndLoadWidget(res: any) {
    const { email: emailAddress } = user;
    const {
      sub: auth0UserId,
      roles,
      token,
      organizationId: userOrgId,
    } = superiorUser;

    const { financialOrganizationId: currentOrgId } = organization;
    const financialOrganizationId = currentOrgId || userOrgId;

    const params: HubspotTokenRequestParams = {
      financialOrganizationId,
      auth0UserId,
      emailAddress,
      roles,
    };  

    const { data: { token: identificationToken = '' } = {} } =
      await getHubspotChatToken(params, financialOrganizationId, token);

    Object.assign((window as any).hsConversationsSettings, {
      identificationEmail: res.email,
      identificationToken,
    });

    (window as any).HubSpotConversations?.widget.load();
  }

  /* 
    Runs after script load but just before widget is painted in DOM.
    There could be a race condition where HubSpotConversations is
    undefined if the token fetch is super speedy (< ~75ms—unlikely).
    Maybe queue the widget load with a generous timeout if that happens.
  */
  function onLiveChatLoad() {
    const { email = '', name = '' } = user;
    const firstName = name.split(' ')[0];
    const lastName = name.split(' ')[1];
    const data = {
      email,
      firstName,
      lastName,
    };

    // setIsHubspotScriptLoaded(true);

    if (organization.financialOrganizationId) {
      getTokenAndLoadWidget(data);
    }
  }

  // Initial append of the script and handle token after the chat script loads
  useEffect(() => {
    if (
      superiorUser &&
      superiorUser.token &&
      !superiorUser.roles.includes(UserRole.accountOwner) &&
      !(window as any).Cypress
    ) {
      // Dynamically append and make it look like this:
      // <script type="text/javascript" id="hs-script-loader" async src="<src>"></script>
        Object.assign(window, {
          // https://legacydocs.hubspot.com/docs/methods/conversations_api/hsconversationssettings
          hsConversationsSettings: { loadImmediately: false }, // Flag to wait for manual widget load
        });

        document.head.append(
          Object.assign(document.createElement('script'), {
            id: 'hs-script-loader',
            src: '//js-na1.hs-scripts.com/20075435.js',
            async: true,
            onload: onLiveChatLoad,
          })
        );
    }
  }, [superiorUser.token]);

  // Re-fetch a token and re-render widget when the org changes for multi-org users
  useEffect(() => {
    if(superiorUser &&
      superiorUser.token &&
      !superiorUser.roles.includes(UserRole.accountOwner)){
      onLiveChatLoad();
    }
  }, [organization.financialOrganizationId, user,superiorUser.token]);

  return null; // No output; This component is 100% side effect
}
