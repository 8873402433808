import React, { useEffect, useState } from 'react';
import { Container, LinearProgress, Box, Grid, useTheme } from '@mui/material';
import { Formik, Form, useFormikContext } from 'formik';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { useUser } from '../../../auth/useUser';
import SiraSearchField from '../SiraSearchField';
import {
  searchAccountOwner,
  searchSimytarlocAccountOwner,
} from '../../../api/AccountOwnerApi';
import { AccountOwnerSearchForm } from './AccountSearch';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import { AccountMemberValue } from '../../../api/OrganizationApi.d';
import AccountOwnerInfoCard from '../newAccount/AccountOwnerInfoCard';
import { AccountOwner } from '../../../api/AccountOwnerApi.d';
import { set } from 'cypress/types/lodash';

interface AccountSearchFormProps {
  submitOnMount?: boolean;
  setResponse?: Function;
  initialValues?: AccountOwnerSearchForm;
  owner?: AccountOwner;
}

export const ACCOUNT_SEARCH_INIT = { query: '' };

const SEARCH_QUERY_SCHEMA = yup.object().shape({
  query: yup.string().required().max(60).label('Search term'),
});

function AccountSearchFormContent(props) {
  const { submitOnMount, submitSearch, isSymitar = false } = props;
  const { isSubmitting, values } = useFormikContext();
  const { query } = values as AccountOwnerSearchForm;
  const {
    organization: { accountNumberValue },
  } = useGlobalContext();
  const headerAccountName =
    accountNumberValue === AccountMemberValue.accountNumber
      ? 'Enter name, tax id, or account number'
      : 'Enter name, tax id, or member number';

  useEffect(() => {
    if (submitOnMount && query) {
      submitSearch(values);
    }
  }, []);

  return (
    <>
      <Container disableGutters>
        <SiraSearchField name="query" label={headerAccountName} />
      </Container>
      {isSubmitting || isSymitar && (
        <Box width="1" mt={2}>
          <LinearProgress color="secondary" />
        </Box>
      )}
    </>
  );
}

function AccountSearchForm(props: AccountSearchFormProps) {
  let isMounted = true;
  const {
    setResponse = () => {},
    initialValues = { query: '' },
    submitOnMount = false,
    owner,
  } = props;
  const { user } = useUser();
  const { addGlobalMessage, organization } = useGlobalContext();
  const queryParams = new URLSearchParams(useLocation().search);
  const queryFromParam = queryParams.get('search') || '';
  const [checkingSymitar, setCheckingSymitar] = useState(false);
  const theme = useTheme();
  const classes = {
    root: {
      overflowX: 'auto' as any,
    },
    formContainer: {
      [theme.breakpoints.down('md')]: {
        flexShrink: 0,
      },
      [theme.breakpoints.up('sm')]: {
        flexShrink: 1,
      },
    },
  };

  const runSymitarSearch = async (
    data: AccountOwnerSearchForm,
  ): Promise<void> => {
    await searchSimytarlocAccountOwner(data, user.organizationId, user.token)
      .then((res) => {
        if (isMounted) {
          setCheckingSymitar(false);
          // map the response to the same shape as the other search
          const cleanData: any = res.data.map((item) => {
            return {
              ...item,
              addressLine1: item.street,
              city: item.city,
              state: item.state,
              zip: item.zip,
              taxpayerIdNumber: item.ssn,
              openDate: item.openDate,
              accountNumber: item.accountNumber,
              accountType: item.accountType,
              memberNumber: item.memberNumber,
            };
          });

          setResponse({
            ...res,
            data: cleanData,
            query: data.query,
          });
        }
      })
      .catch(() => {
        addGlobalMessage('Error fetching account owner search results');
      });
  };

  const submitSearch = async (data: AccountOwnerSearchForm): Promise<void> => {
    await searchAccountOwner(data, user.organizationId, user.token)
      .then((res) => {
        if (isMounted) {
          if (res.data.length > 0) {
            setResponse({ ...res, query: data.query });
          } else {
            if (organization.coreIntegration === 'SYMITAR') {
              setCheckingSymitar(true);
              runSymitarSearch(data);
            } else {
              setResponse({ ...res, query: data.query });
            }
          }
        }
      })
      .catch(() => {
        addGlobalMessage('Error fetching account owner search results');
      });
  };

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (data) => {
        await submitSearch(data);
      }}
      validationSchema={SEARCH_QUERY_SCHEMA}
    >
      {({ setFieldValue }) => {
        useEffect(() => {
          if (queryFromParam) {
            setFieldValue('query', queryFromParam);
            submitSearch({ query: queryFromParam });
          }
        }, []);

        return (
          <Form>
            <Grid container wrap="nowrap" sx={classes.root}>
              <Grid item xs={8} sx={classes.formContainer}>
                <AccountSearchFormContent
                  isSymitar={checkingSymitar}
                  submitSearch={submitSearch}
                  submitOnMount={submitOnMount}
                />
              </Grid>
              {owner ? (
                <Grid item pl={1}>
                  <AccountOwnerInfoCard accountOwner={owner} />
                </Grid>
              ) : null}
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}

export default AccountSearchForm;
