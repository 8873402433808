import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import MergeIcon from '@mui/icons-material/Merge';
import VisibilityIcon from '@mui/icons-material/Visibility';

const CustomMergeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g transform="matrix(.422204 0 0 0.393439 1.288857 5.3869)">
        <path
          d="M12.848579,0c-.291545,0-.573758.098051-.724233.295377L1.740905,11.478121c-.376188.394651.001071,1.182942.602973,1.182942h8.200777v2.236835.065955c-.225713,1.381281-.602876,2.894152-1.129539,4.143882-1.504749,3.617639-4.514038,6.774228-8.727339,8.55016-.6019.197326-.827025.855866-.601312,1.316292l.978378,2.038961c.30095.526202.977792.723191,1.579692.394314c4.514251-1.907482,8.125144-4.932521,10.307032-8.55016c2.257126,3.617639,5.793952,6.642678,10.383441,8.55016.216183.094497.450768.147185.674401.147689-.384321-.515064-.609619-1.12697-.609619-1.785167c0-.976235.494135-1.85143,1.274053-2.456218-3.812203-1.823059-6.548173-4.811987-7.959919-8.206031-.6019-1.315505-.979063-2.828375-1.129538-4.143882v-2.302793h8.126027c.6019,0,1.052739-.590964.601313-1.182941L13.629289,0.295377C13.441196,0.098051,13.140125,0,12.848579,0Z"
          transform="translate(10.4859 1.95003)"
        />
        <path
          d="M9.690366,6.5c0,1.933-1.506351,3.5-3.364535,3.5s-3.364535-1.567-3.364535-3.5s1.50635-3.5,3.364535-3.5s3.364535,1.567,3.364535,3.5Zm-.664409,3.5159C8.285922,10.6318,7.347217,11,6.325831,11C3.936742,11,2,8.98528,2,6.5s1.936742-4.5,4.325831-4.5s4.32583,2.01472,4.32583,4.5c0,1.06251-.353949,2.03901-.946011,2.80884l2.727869,2.83756c.187645.1953.187645.5119,0,.7072s-.492087.1952-.679828,0L9.025957,10.0159Z"
          transform="matrix(.982209-.093269 0.099256 1.045254 30.6769 26.28186)"
          clip-rule="evenodd"
          fill-rule="evenodd"
        />
      </g>
    </SvgIcon>
  );
};

export default CustomMergeIcon;
