import React, { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  Button,
  Box,
  LinearProgress,

} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import {  GridColDef } from '@mui/x-data-grid-pro';
import {
  useGlobalContext,
  usePaginationContext,
} from '../../auth/useGlobalContext';
import { useUser } from '../../auth/useUser';

import {
  getOrgCoreSetting,
  updateCoreIntegrationOrgFields,
  updateEsignOrgFields,
  updateEsignOrgFieldsMappings,
} from '../../api/OrganizationApi';
import {
  FiduciaryType,
  DocumentationAddress,
  SpousalConsentOption,
  EsignLandingPage,
} from '../../api/OrganizationApi.d';

import { errorMessages } from '../../utils/errorhandling.utils';
import FinancialOrgCoreIntegrationSettings, { FINANCIAL_CORE_SETTINGS_INT } from '../form/organization/FinancialOrgCoreIntegrationSettings';
import { UserRole } from '../../api/UserApi.d';

interface EsignSettings {
  eSign: boolean;
  eSignWithSuperior: boolean;
  eSignPassthroughId: boolean;
  esignDefaultAppUserId: string;
  esignUserId: string;
  esignHostFiid: string;
  eSignLandingPage: EsignLandingPage;
  eSignApiKey: string;
  eSignBaseUrl: string;
}

export interface CoreIntegrationSettings {
  id: number;
  financialOrganizationId: number;
  clientNumber: string;
  clientSystemUserNumber: number;
  clientLogin: string;
  shareTransactionSourceCode: number;
  deviceName: string;
  deviceNumber: number;
  coreUrl: string;
}

function OrganizationCoreIntegrationSettings() {
  let isMounted = true;
  const { organization, setCurrentOrg, addGlobalMessage } = useGlobalContext();
  const { setGlobalPageSize, globalPageSize } = usePaginationContext();

  const { user } = useUser();
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [esignSettingsData, setEsignSettingsData] = useState([] as any);

  const [isAdvancedEsignSetting, setAdvancedEsignSetting] = useState(false);

  const {
    name = '',
    financialOrganizationId = '',
    legalName = '',
    federalTaxId = '',
    fiduciaryType = FiduciaryType.default,
    spousalConsentRequired = SpousalConsentOption.community,
    solution = '',
    documentationAddress = '' as DocumentationAddress,
    eSign = false,
    eSignWithSuperior = false,
  } = organization;

  const [organizationCoreIntegration, setOrganizationCoreIntegration] =
    useState({
      id: 0,
      financialOrganizationId: 0,
      clientNumber: '',
      clientSystemUserNumber: 0,
      clientLogin: '',
      shareTransactionSourceCode: 0,
      deviceName: '',
      deviceNumber: 0,
      coreUrl: '',
    } as any);

  const columns = [
    {
      field: 'superiorValue',
      headerName: 'Superior Value',
      width: 200,
    },
    {
      field: 'immField',
      headerName: 'Index Field Name',
      width: 200,
      editable: true,
    },
  ] as GridColDef[];

  async function saveOrganization(data) {
    setIsLoading(true);

    await updateCoreIntegrationOrgFields(data, user.organizationId, user.token)
      .then((res) => {
        if (isMounted) {
          setIsEditing(false);
          setIsLoading(false);
          setOrganizationCoreIntegration(res.data);
          addGlobalMessage('Changes saved successfully', {
            severity: 'success',
          });
        }
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
          addGlobalMessage(
            errorMessages(err) || 'Error saving organization information',
          );
        }
      });
  }

  async function fetchOrganizationCoreIntegrationSettings() {
    setIsLoading(true);
    // get organization Advanced settings
    await getOrgCoreSetting(user.organizationId, user.token)
      .then((res) => {
        if (isMounted) {
          setIsLoading(false);
          setOrganizationCoreIntegration(res.data);
        }
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
          addGlobalMessage(
            errorMessages(err) ||
              'Error fetching organization core integration information',
          );
        }
      });
  }

  async function saveTable() {
    const removeId = esignSettingsData.map(
      ({ id, superiorValue, ...keepAttrs }) => keepAttrs,
    );
    await updateEsignOrgFieldsMappings(
      removeId,
      user.organizationId,
      user.token,
    )
      .then(() => {
        if (isMounted) {
          addGlobalMessage('Changes saved successfully', {
            severity: 'success',
          });
        }
      })
      .catch((err) => {
        if (isMounted) {
          setAdvancedEsignSetting(true);
          addGlobalMessage(
            errorMessages(err) || 'Error saving organization information',
          );
        }
      });
  }

  useEffect(() => {
    fetchOrganizationCoreIntegrationSettings();
    return () => {
      isMounted = false;
    };
  }, []);

  if (isLoading) {
    return (
      <Box width="1" pt={10} pb={10} textAlign="center">
        <LinearProgress color="secondary" />
      </Box>
    );
  }

  return isEditing ? (
    <FinancialOrgCoreIntegrationSettings
      initialValues={{
        ...FINANCIAL_CORE_SETTINGS_INT,
        ...organizationCoreIntegration,
      }}
      onSubmit={async (data) => {
        await saveOrganization(data);
      }}
      onCancel={() => {
        setIsEditing(false);
      }}
    />
  ) : (
    <Box width="1">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Client Login </Typography>
          <Typography variant="body1">
            {organizationCoreIntegration.clientLogin}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Client Number </Typography>
          <Typography variant="body1">
            {organizationCoreIntegration.clientNumber}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Client System User Number </Typography>
          <Typography variant="body1">
            {organizationCoreIntegration.clientSystemUserNumber}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Core Url </Typography>
          <Typography variant="body1">
            {organizationCoreIntegration.coreUrl}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Device Name </Typography>
          <Typography variant="body1">
            {organizationCoreIntegration.deviceName}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Device Number </Typography>
          <Typography variant="body1">
            {organizationCoreIntegration.deviceNumber}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Share Transaction Source Code</Typography>
          <Typography variant="body1">
            {organizationCoreIntegration.shareTransactionSourceCode}
          </Typography>
        </Grid>
      </Grid>

      {user.roles.includes(UserRole.multi) ?<Box mt={4}>
        <Button
          aria-label="Edit organization settings"
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={() => {
            setIsEditing(true);
          }}
        >
          Edit Details
        </Button>
      </Box> :null}
    </Box>
  );
}

export default OrganizationCoreIntegrationSettings;
