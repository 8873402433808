import React, { useState } from 'react';
import {
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Paper,
  Typography,
  Grid,
  LinearProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useGlobalContext } from '../auth/useGlobalContext';
import Layout from '../components/Layout';
import PaperlessPage from '../components/PaperlessPage';
import OrganizationProfileDetails from '../components/organizationProfile/OrganizationProfileDetails';
import OrganizationProfileBranchDetails from '../components/organizationProfile/OrganizationProfileBranchDetails';
import ObscuredTaxId, { TaxIdFormat } from '../components/ObscuredTaxId';
import OrganizationStateTaxIdDetails from '../components/organizationProfile/OrganizationStateTaxIdDetails';
import OrganizationInvestmentMaintenance from '../components/organizationProfile/OrganizationInvestmentMaintenance';
import OrganizationProfileOptions from '../components/organizationProfile/OrganizationProfileOptions';
import OrganizationBrandingDetails from '../components/organizationProfile/OrganizationBrandingDetails';
import OrganizationDocuments from '../components/organizationProfile/OrganizationDocuments';
import OrganizationEsignSettings from '../components/organizationProfile/OrganizationEsignSettings';
import OrganizationTaxReportingSettings from '../components/organizationProfile/OrganizationTaxReportSettings';
import { Solution } from '../api/OrganizationApi.d';
import OrganizationTransactionSettings from '../components/organizationProfile/OrganizationTransactionSettings';
import OrganizationCoreIntegrationSettings from '../components/organizationProfile/OrganizationCoreIntegrationSettings';

export interface OrganizationPageParams {
  orgId: string;
}

function OrganizationProfile() {
  const theme = useTheme();
  const [isLoading] = useState(false as boolean);
  const { organization } = useGlobalContext();
  const {
    branches = [],
    federalTaxId = '',
    financialOrganizationId = '',
    legalName = '',
    name = '',
    solution = [],
  } = organization || {};

  const primaryBranchInfo = branches.find(
    ({ primaryBranch = false }) => primaryBranch
  );
  const classes = {
    profileCard: {
      color: theme.palette.grey[300],
      marginTop: theme.spacing(2),
      padding: theme.spacing(4),
      background: theme.palette.primary.main,
    },
    moreInformation: {
      color: theme.palette.grey[300],
      paddingTop: theme.spacing(1),
      background: theme.palette.primary.main,
    },
  };

  return (
    <Layout>
      <PaperlessPage>
        <>
          <Typography variant="overline" gutterBottom>
            Organization Profile
          </Typography>

          <Paper elevation={3} sx={classes.profileCard}>
            {isLoading ? (
              <LinearProgress color="secondary" />
            ) : (
              <>
                <Typography variant="overline" color="secondary">
                  Name
                </Typography>

                <Typography color="secondary" variant="h3" gutterBottom>
                  {name}
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={5}>
                    <Typography
                      variant="subtitle2"
                      color="secondary"
                      gutterBottom
                    >
                      Legal Name
                    </Typography>

                    <Typography variant="body1">{legalName}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography
                      variant="subtitle2"
                      color="secondary"
                      gutterBottom
                    >
                      Federal Tax ID
                    </Typography>

                    <Typography variant="body1">
                      <ObscuredTaxId
                        taxId={federalTaxId}
                        format={TaxIdFormat.ein}
                      />
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Typography
                      variant="subtitle2"
                      color="secondary"
                      gutterBottom
                    >
                      Org Number
                    </Typography>

                    <Typography variant="body1">
                      {financialOrganizationId}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Accordion elevation={0} sx={classes.moreInformation}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon color="secondary" />}
                      >
                        <Typography variant="h5" color="secondary">
                          More Information
                        </Typography>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Grid container spacing={3}>
                          {primaryBranchInfo && (
                            <>
                              <Grid item xs={12} md={6}>
                                <Typography variant="h6">
                                  Mailing Address
                                </Typography>
                                <Typography variant="body1">
                                  {primaryBranchInfo.addressLine1}
                                </Typography>
                                <Typography variant="body1">
                                  {primaryBranchInfo.addressLine2}
                                </Typography>
                                <Typography variant="body1">
                                  {primaryBranchInfo.city}{' '}
                                  {primaryBranchInfo.state}{' '}
                                  {primaryBranchInfo.zip}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <Typography variant="h6">
                                  Phone Number
                                </Typography>
                                <Typography variant="body1">
                                  {primaryBranchInfo.phoneNumber}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <Typography variant="h6">Email</Typography>
                                <Typography variant="body1">
                                  {primaryBranchInfo.emailAddress}
                                </Typography>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              </>
            )}
          </Paper>

          <Box mt={4}>
            {/* Org Info */}
            <Accordion 
              slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}
            >
              <AccordionSummary
                disabled={isLoading}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="h5" color="secondary">
                  Organization Information
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <OrganizationProfileDetails />
              </AccordionDetails>
            </Accordion>

            {/* Core Integration Settings  */}
            <Accordion              
            slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}>
              <AccordionSummary
                disabled={isLoading}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="h5" color="secondary">
                  Core Integration Settings
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <OrganizationCoreIntegrationSettings />
              </AccordionDetails>
            </Accordion>

            {/* Organization Esign Settings  */}
            <Accordion              slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}>
              <AccordionSummary
                disabled={isLoading}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="h5" color="secondary">
                  Organization E-sign Settings
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <OrganizationEsignSettings />
              </AccordionDetails>
            </Accordion>

            {/* Org Options */}
            <Accordion
                           slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}
            >
              <AccordionSummary
                disabled={isLoading}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="h5" color="secondary">
                  Profile Options
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <OrganizationProfileOptions />
              </AccordionDetails>
            </Accordion>

            {/* Branches */}
            <Accordion              slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}>
              <AccordionSummary
                disabled={isLoading}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="h5" color="secondary">
                  Branch Information
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <OrganizationProfileBranchDetails />
              </AccordionDetails>
            </Accordion>

            {/* State Withholding */}
            <Accordion              slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}>
              <AccordionSummary
                disabled={isLoading}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="h5" color="secondary">
                  State Withholding and Reporting
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <OrganizationStateTaxIdDetails />
              </AccordionDetails>
            </Accordion>

            {/* Transactions Settings */}
            <Accordion              slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}>
              <AccordionSummary
                disabled={isLoading}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="h5" color="secondary">
                  Transaction Settings
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <OrganizationTransactionSettings
                  financialOrganizationId={financialOrganizationId}
                />
              </AccordionDetails>
            </Accordion>

            {/* Investment Rates */}
            <Accordion              slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}>
              <AccordionSummary
                disabled={isLoading}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="h5" color="secondary">
                  Investment Rates
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <OrganizationInvestmentMaintenance
                  financialOrganizationId={financialOrganizationId}
                />
              </AccordionDetails>
            </Accordion>

            {/* Branding */}
            <Accordion              slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}>
              <AccordionSummary
                disabled={isLoading}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="h5" color="secondary">
                  Organization Branding
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <OrganizationBrandingDetails />
              </AccordionDetails>
            </Accordion>

            {/* Organizational Documents */}
            <Accordion              slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}>
              <AccordionSummary
                disabled={isLoading}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="h5" color="secondary">
                  Organizational Documents
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <OrganizationDocuments />
              </AccordionDetails>
            </Accordion>
          </Box>
        </>
      </PaperlessPage>
    </Layout>
  );
}

export default OrganizationProfile;
