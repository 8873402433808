import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Layout from '../Layout';
import PaperlessPage from '../PaperlessPage';
import ActionCard from '../ActionCard';

import MergeIcon from '@mui/icons-material/Merge';
import CustomMergeIcon from '../../icons/MergerIcon';
import CreateMergeIcon from '../../icons/CreateMergeIcon';

function OrganizationMerger() {
  return (
    <Layout>
      <PaperlessPage>
        <>
          <Typography data-qa="merge-header" variant="overline" gutterBottom>
            Organizational Merge
          </Typography>
          <Grid container spacing={3} wrap="wrap" mt={2}>
            <ActionCard
              dataQa="create-merger"
              iconImage={
                <CreateMergeIcon
                  color="secondary"
                  style={{ fontSize: 56 }}
                />
              }
              buttonText="Create Merger"
              route="/merger/CreateMerger"
            />
            <ActionCard
              dataQa="view-claim"
              iconImage={
                  <CustomMergeIcon
                    color="secondary"
                    style={{ fontSize: 60 }}
                  />
              }
              buttonText="View Mergers"
              route="/merger/ViewMergers"
            />
          </Grid>
        </>
      </PaperlessPage>
    </Layout>
  );
}

export default OrganizationMerger;
