import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import moment from 'moment';
import SiraTextField from '../SiraTextField';
import SiraDateField from '../SiraDateField';
import SiraSelectField from '../SiraSelectField';
import {
  AccountContribution,
  DepositMethod,
  DepositType,
  PostponementCode,
  PostponementReason,
  RepaymentCode,
} from '../../../api/ContributionApi.d';
import {
  postponementCodes,
  postponementReasonsExecutiveOrder,
  postponementReasonsPublicLaw,
  repaymentCodes,
} from '../../../app.constants';

function DepositTypeFields() {
  const { values, setFieldValue, setValues } =
    useFormikContext<AccountContribution>();
  const { depositType, depositMethod, postponedCode, effectiveDate, postponedYear} =
    values as AccountContribution;
  const currentYear = useMemo(() => {
    const dateChecker =
      effectiveDate && effectiveDate !== ''
        ? effectiveDate
        : new Date().toISOString().slice(0, 10);
    return moment(dateChecker).year();
  }, [effectiveDate]);
  const priorYear = useMemo(() => {
    const dateChecker =
      effectiveDate && effectiveDate !== ''
        ? effectiveDate
        : new Date().toISOString().slice(0, 10);
    const date = moment(dateChecker);
    return date.clone().subtract(1, 'year').year();
  }, [effectiveDate]);
  const [displayTaxyear, setdisplayTaxyear] = useState(false as boolean);
  const [isInitialized, setIsInitialized] = useState(false as boolean);
  const [showPostponedYear, setShowPostponedYear] = useState(false as boolean);

  function setTaxYear() {
    if (depositType === DepositType.regularPrior) {
      setFieldValue('taxYear', priorYear, false);
    } else if (depositType !== DepositType.postponed) {
      setFieldValue('taxYear', currentYear, false);
    }
  }

  // Update tax year whenever the depositType changes so it's current in the read-only field
  useEffect(() => {
    // Reset values on option change only if the component has already done its initial render pass
    // (don't overwrite existing values)
    if (isInitialized) {
      setValues({
        ...values,
        postponedCode: '' as PostponementCode,
        postponedReason: '' as PostponementReason,
        repaymentCode: '' as RepaymentCode,
      });
    } else {
      setIsInitialized(true);
    }

    setTaxYear();
  }, [depositType]);

  useEffect(() => {
    if (
      postponedCode === PostponementCode.fedDisasterArea ||
      postponedCode === PostponementCode.executiveOrder ||
      postponedCode === PostponementCode.publicLaw
    ) {
      setdisplayTaxyear(true);
      setShowPostponedYear(true);
    } else if (postponedCode === PostponementCode.qaulifiedPlan) {
      setdisplayTaxyear(true);
      setShowPostponedYear(false);
    } else {
      setShowPostponedYear(false);
      setShowPostponedYear(false);
    }
  }, [postponedCode]);

  return (
    <>
      <Grid item xs={12}>
        {depositType !== DepositType.postponed && (
          <SiraTextField disabled name="taxYear" label="Tax Year" />
        )}
      </Grid>
      {depositType === DepositType.postponed && (
        <>
          <Grid item xs={12}>
            <SiraSelectField
              name="postponedCode"
              items={postponementCodes}
              label="Postponement Code"
            />
          </Grid>

          {showPostponedYear ? (
            <Grid item xs={12}>
              <SiraTextField
                name="postponedYear"
                label="Postponed Year"
                afterContent={
                  <Typography variant="body2">
                    The year to which the postponed contribution is credited.
                  </Typography>
                }
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <SiraTextField
              disabled={displayTaxyear}
              name="taxYear"
              label="Tax Year"
            />
          </Grid>

          {[
            PostponementCode.executiveOrder,
            PostponementCode.publicLaw,
          ].includes(postponedCode) && (
            <>
              <Grid item xs={12}>
                <SiraSelectField
                  name="postponedReason"
                  items={
                    postponedCode === PostponementCode.executiveOrder
                      ? postponementReasonsExecutiveOrder
                      : postponementReasonsPublicLaw
                  }
                  label="Postponement Reason"
                />
              </Grid>
            </>
          )}
        </>
      )}
      {/* Distribution Date if ROLLEVER and TRANFER or CASH */}
      {depositType === DepositType.rollover &&
        [
          DepositMethod.cash,
          DepositMethod.transfer,
          DepositMethod.direct,
        ].includes(depositMethod) && (
          <Grid item xs={12}>
            <SiraDateField
              name="dateOfWithdrawal"
              label="Date money left other account"
            />
          </Grid>
        )}
      {depositType === DepositType.repayment && (
        <Grid item xs={12}>
          <SiraSelectField
            name="repaymentCode"
            items={repaymentCodes}
            label="Repayment Code"
          />
        </Grid>
      )}
    </>
  );
}

export default DepositTypeFields;
