import axiosInstance from '../utils/axiosInterceptor';
import format from 'string-format';

const urlBase = process.env.REACT_APP_API_BASE_URL;
const mergerUrl = '/api/org/{0.parentOrgId}/merger';
const mergerSharedUrl =
  '/api/org/{0.parentOrgId}/merger/{0.mergerId}/move/sharedAccounts';
const mergerAccountOwnersUrl =
  '/api/org/{0.parentOrgId}/merger/{0.mergerId}/move/accountOwners';
const orgMergerUrl = '/api/org/merger';
const countMergerUrl = '/api/org/merger/account';
const editMerger = '/api/org/{0.parentOrgId}/merger/{0.mergerId}';
const createAmendmenturl = '/api/amendmentNotificationJob';
//get auth0id from session storage
const auth0Id = sessionStorage.getItem('auth0Id');

// create new merger
export async function createAmendments(data: any, token: string) {
  const url = `${urlBase}${format(createAmendmenturl)}`;
  return axiosInstance.post(
    url,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

// create new merger
export async function createMerger(data: any,params, parentOrgId,token: string) {
  const url = `${urlBase}${format(mergerUrl, { parentOrgId })}`;

  return axiosInstance.post(
    url,
    data,
    {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

// update merger
export async function updateMerger(parentOrgId,mergerId,data: any, token: string) {

  const url = `${urlBase}${format(editMerger, { parentOrgId, mergerId })}`;

  return axiosInstance.put(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

// retreive all active and inactive mergers
export async function getIndividualMerger(data: any, token: string) {
  const parentOrgId = data.parentOrgId;
  const url = `${urlBase}${format(mergerUrl, { parentOrgId })}`;

  return axiosInstance.get(url, {
    headers: {
      // add custom headers here
      Authorization: `Bearer ${token}`,
      Auth_0_Id: auth0Id,
    },
  });
}

// retreive all active and inactive mergers
export async function getMerger(token: string) {
  const url = `${urlBase}${format(orgMergerUrl)}`;

  return axiosInstance.get(url, {
    headers: {
      // add custom headers here
      Authorization: `Bearer ${token}`,
      Auth_0_Id: auth0Id,
    },
  });
}

//get account merger counts
export async function getAccountMergerCounts(data: any, token: string) {
  const url = `${urlBase}${format(countMergerUrl)}`;

  const params = {
    survivingOrgId: data.survivingOrgId,
    mergingOrgId: data.mergingOrgId,
  };
  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
      Auth_0_Id: auth0Id,
    },
  });
}

// update account owners
export async function updateAccountOwners(data: any, token: string) {
  const url = `${urlBase}${format(mergerAccountOwnersUrl, {
    parentOrgId: data.parentOrgId,
    mergerId: data.mergerId,
  })}`;

  return axiosInstance.put(
    url,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Auth_0_Id: auth0Id,
      },
    },
  );
}

//update shared acounts
export async function updateSharedAccounts(data: any, token: string) {
  const url = `${urlBase}${format(mergerSharedUrl, {
    parentOrgId: data.parentOrgId,
    mergerId: data.mergerId,
  })}`;

  return axiosInstance.put(
    url,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Auth_0_Id: auth0Id,
      },
    },
  );
}
