import React, { useEffect, useState } from 'react';

import { Box, Paper, Typography, Button, Popover, Grid } from '@mui/material';

import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import Cancel from '@mui/icons-material/Cancel';

import { DataGridPro, GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { useUser } from '../../auth/useUser';
import { getMerger } from '../../api/OrganizationMergerApi';
import { errorMessages } from '../../utils/errorhandling.utils';
import {
  useGlobalContext,
  usePaginationContext,
} from '../../auth/useGlobalContext';

import {
  globalPaginationOptions,
  MergingOrgStatus,
  OrgInformationStatus,
} from '../../app.constants';
import Layout from '../Layout';
import FormPaper from '../FormPaper';
import SiraNoRowsOverlay from '../SiraNoRowsOverlay';
import { useNavigate } from 'react-router-dom';

function OrgViewMerger() {
  let isMounted = true;
  const { user } = useUser();
  const { addGlobalMessage, organization } = useGlobalContext();
  const { setGlobalPageSize, globalPageSize } = usePaginationContext();
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [mergerData, setMergerData] = useState([] as any[]);
  const navigate = useNavigate();

  const classes = {
    organizationLink: {
      justifyContent: 'flex-start' as any,
      width: '100%',
      textDecoration: 'none' as any,
      textTransform: 'none' as any,
    },
  };

  const columns: GridColDef[] = [
    {
      field: 'financialOrganizationName',
      headerName: 'Surviving Name',
      flex: 2,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            data-qa="searchResult_button"
            onClick={() => {
              onResultClick(row);
            }}
            sx={classes.organizationLink}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'financialOrganizationId',
      headerName: 'Surviving ID',
      flex: 0.5,
    },
    {
      field: 'mergingFinancialOrganizationName',
      headerName: 'Merging Name',
      flex: 2,
    },
    {
      field: 'mergingFinancialOrganizationId',
      headerName: 'Merging ID',
      flex: 0.5,
    },
    {
      field: 'taxYearStart',
      headerName: 'Start Tax Year',
      width: 65,
      sortable: true,
    },
    {
      field: 'taxYearEnd',
      headerName: 'End Tax Year',
      width: 65,
      sortable: true,
    },
    {
      field: 'mergerStatus',
      headerName: 'Merger Status',
      width: 200,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;
        const statusComponents = {
          [MergingOrgStatus.initiated]: (
            <Box display="flex" alignItems="center" pt={2}>
              <PendingIcon sx={{ color: 'info.main' }} />
              <Typography
                variant="body1"
                sx={{ color: 'info.main', paddingLeft: 1 }}
              >
                Initiated
              </Typography>
            </Box>
          ),
          [MergingOrgStatus.jointMerger]: (
            <Box display="flex"  alignItems="center" pt={2}>
              <CheckCircleIcon sx={{ color: 'success.main' }} />
              <Typography
                variant="body1"
                sx={{ color: 'success.main', paddingLeft: 1 }}
              >
                Join Merger
              </Typography>
            </Box>
          ),
          [MergingOrgStatus.allAccounts]: (
            <Box display="flex" alignItems="center" pt={2}>
              <CheckCircleIcon sx={{ color: 'success.main' }} />
              <Typography
                variant="body1"
                sx={{ color: 'success.main', paddingLeft: 1 }}
              >
                All Accounts Merged
              </Typography>
            </Box>
          ),
        };

        return (
          statusComponents[row.mergerStatus as MergingOrgStatus] ||
          row.mergerStatus
        );
      },
    },
  ];

  const onResultClick = (row: any) => {
    // navigate to merge view page
    navigate(
      `/merger/ViewMergers/survivingMerger?survivingOrg=${row.financialOrganizationId}&mergingOrg=${row.mergingFinancialOrganizationId}`,
    );
  };

  

  // retreive all mergers
  async function retreiveAllMergers() {
    await getMerger(user.token)
      .then((response) => {
        if (response.data) {
          setIsLoading(false);
          // adding id to the data before setting the state
          const data = response.data.map((item: any, index: number) => {
            return { ...item, id: index };
          });
          setMergerData(data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        addGlobalMessage(errorMessages(error) || 'Error fetching mergers');
      });
  }

  // on screen load, get all mergers
  useEffect(() => {
    // call api to get all mergers
    if (user.token) {
      setIsLoading(true);
      retreiveAllMergers();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Layout>
      <FormPaper>
        <Typography variant="overline" gutterBottom>
          Organizational Merger
        </Typography>

        <Typography color="secondary" variant="h1" gutterBottom>
          View Mergers
        </Typography>
        <Grid container spacing={3} wrap="wrap">
          <Grid item xs={12}>
            <DataGridPro
              sx={{
                filter: isLoading ? 'blur(5px)' : '',
                transition: 'filter 0.33s ease-in-out',
              }}
              slots={{
                noRowsOverlay: SiraNoRowsOverlay,
              }}
              initialState={{
                pagination: { paginationModel: { pageSize: globalPageSize } },
              }}
              pagination
              pageSizeOptions={globalPaginationOptions}
              onPaginationModelChange={setGlobalPageSize}
              autoHeight
              columns={columns}
              rows={mergerData}
            />{' '}
          </Grid>
        </Grid>
      </FormPaper>
    </Layout>
  );
}

export default OrgViewMerger;
