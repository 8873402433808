import React, { useState, useCallback, createContext, useEffect } from 'react';
import {
  Alert,
  AlertProps,
  Box,
  StyledEngineProvider,
  ThemeProvider,
  Typography,
} from '@mui/material';
import ReportIcon from '@mui/icons-material/Report';

import { Organization } from '../api/OrganizationApi.d';
import { getOrg } from '../api/OrganizationApi';
import { useUser } from './useUser';
import { getConstantsMapping } from '../api/SharedTextApi';
import { ConstantsMappingKey, TaxYearsMapping } from '../api/SharedTextApi.d';
import { MessageSnackbarProps } from '../components/MessageSnackbar';
import { getAllRoles } from '../api/RoleApi';
import { Role } from '../api/RoleApi.d';
import { User, UserRole } from '../api/UserApi.d';
import {
  createSSOUser,
  getCustomerPortalUser,
  getUsers,
} from '../api/UserApi';
import FormPaper from '../components/FormPaper';
import createOwnerTheme from '../themes/ownerTheme';
import { set } from 'cypress/types/lodash';

export const GlobalContext = createContext(null);
export const PaginationContext = createContext(null);
let freshId = 1;

export function GlobalContextProvider({ children }) {
  const { user } = useUser();
  const [constantsMap, setConstantsMap] = useState({} as any);
  const [roles, setRoles] = useState([] as Array<Role>);
  const [organization, updateCurrentOrg] = useState({} as Organization);
  const [countries, setCountries] = useState({} as any);
  const [beneficiaryClaimStatus, setBeneficiaryClaimStatus] = useState(
    {} as any
  );
  const [beneDistributionOptions, setBeneDistributionOptions] = useState(
    {} as any
  );
  const [taxFilingDeadline, setTaxFilingDeadline] = useState({} as any);
  const [globalMessages, setGlobalMesages] = useState(
    [] as Array<MessageSnackbarProps>
  );
  const [taxYears, setTaxYears] = useState([] as Array<TaxYearsMapping>);
  const [customerPortalUser, setCustomerPortalUser] = useState(
    false as boolean
  );
  const [customerPortalId, setCustomerPortalId] = useState(null as string);
  const [customerPortalError, setCustomerPortalError] = useState('' as string);
  const [isCustomerPortalError, setIsCustomerPortalError] = useState(
    false as boolean
  );
  const [currentUser, setCurrentUser] = useState<User>({});
  const [irsFieldData, setIrsFieldData] = useState({} as any);
  const [activeTaxYear, setActiveYear] = useState('' as string);
  const [taxFileIndex, setTaxFileIndex] = useState({} as number);
  const [userList, setUserList] = useState<Array<User>>([]);
  const [auth0Id, setAuth0Id] =  useState<string>();

  // Fetch the org data for the current user's org
  async function getOrgData() {
    await getOrg(user.organizationId, user.token, user).then((res) => {
      updateCurrentOrg(res.data);
    });
  }

  // Fetch the constants map
  async function getConstantsData() {
    await getConstantsMapping(user.token, user).then((res) => {
      setConstantsMap(res.data);
      setCountries(res.data.COUNTRY);
      setTaxFilingDeadline(res.data.TAX_FILING_DEADLINES);
      setBeneficiaryClaimStatus(res.data.BENEFICIARY_CLAIM_STATUS);
      setBeneDistributionOptions(res.data.BENEFICIARY_DISTRIBUTION_OPTIONS);
      setTaxYears(res.data.TAX_YEARS);
      setIrsFieldData(res.data.IRS_FILE_FIELD_NAMES);
      setTaxFileIndex(res.data.TAX_FILE_INDEX);
    });
  }

  // Fetch available ap roles
  async function getRoles() {
    await getAllRoles(user.token, user).then((res) => {
      setRoles(res.data);
    });
  }

  // Fetch rep info

  const setRepInfo = async (): Promise<void> => {
    const isMultiOrg = user.organizationId !== null ? false : true;
    await getUsers(user.organizationId, user.token, {
      includeMultiOrgUsers: isMultiOrg,
    }).then((res) => {
      setUserList(res.data);

      // isPresent, is the user the currentUser
      const isPresent = res.data.find((data) => {
        return data.auth0UserId === user.sub && data;
      });

      // if isPresent is undefined and not an admin/multiOrg user create new user in table
      if (isPresent === undefined && !user.roles.includes(UserRole.multi)) {
        createNewUser();
      } else {
        setCurrentUser(isPresent);
      }
    });
  };

  useEffect(() => {
    if (taxYears.length > 0) {
      const activeYear = taxYears.find((year) => year.active === true)?.taxYear;
      setActiveYear(activeYear);
    }
  }, [taxYears]);

  // Update the current organization at the global level
  const setCurrentOrg = useCallback(
    (data: Organization = {} as any) => {
      updateCurrentOrg({ ...organization, ...data });
    },
    [organization.financialOrganizationId]
  );

  // Get the constant value by map and set keys or show default
  const getAppConstant = useCallback(
    (key: ConstantsMappingKey, value: string): string => {
      return (
        (constantsMap && constantsMap[key] && constantsMap[key][value]) || value
      );
    },
    [constantsMap]
  );

  // Push a global message to the queue at the global level
  const addGlobalMessage = useCallback(
    (content: string, config?: AlertProps) => {
      if (content) {
        setGlobalMesages(() => [
          ...globalMessages,
          { id: freshId + 1, content, config },
        ]);
        freshId += 1;
      }
    },
    [globalMessages]
  );

  // Check if user exists in the system
  async function checkIfUserExists() {
    // if user exists, we will get the user information and set the user information in the global context
    // if user does not exist, we will call the account verification form to have the user enter additional information
    await getCustomerPortalUser(user.organizationId, user.token)
      .then((res) => {
        if (
          (res.data &&
            Object.keys(res.data).length > 0 &&
            res.data.auth0UserId !== null) ||
          res.data.auth0UserId !== undefined
        ) {
          getRoles();
          setCustomerPortalUser(true);
          setCustomerPortalId(res.data.customerPortalId);
          setIsCustomerPortalError(false);
        } else {
          setCustomerPortalUser(false);
          setIsCustomerPortalError(false);
        }
      })
      .catch((err) => {
        // set user to not be a Account Owner user get additional information from user
        // set error message to display to user
        setIsCustomerPortalError(true);
        setCustomerPortalError(
          `Error Msg: ${err.response.data.message}. Code ${err.response.data.status}`
        );
      });
  }

  function initializeGlobalContext() {
    // check to see if token of user role is customerportal user and run the create user api call to check if user exists
    if (user.token) {
      if (user.roles.includes(UserRole.accountOwner)) {
        getOrgData();
        getConstantsData();
        checkIfUserExists();
      } else {
        if (user.organizationId) {
          getOrgData();
          setRepInfo();
        }

        // These calls don't need an org ID so call them with token
        getRoles();
        getConstantsData();
      }
    }
  }

  // Create SSO user
  async function createNewUser() {
    await createSSOUser(user, user.organizationId, user.token).then((res) => {
      setCurrentUser(res.data);
    });
  }

  useEffect(() => {
    initializeGlobalContext();
    sessionStorage.setItem('auth0id', user.sub);
  }, [user.token, user.organizationId]);

  if (isCustomerPortalError) {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={createOwnerTheme(organization)}>
          <FormPaper>
            <>
              <Box textAlign="center" maxWidth="sm" ml="auto" mr="auto">
                <Typography variant="h4" pb={3}>
                  Login Error
                </Typography>
                <ReportIcon color="error" sx={{ fontSize: '126px' }} />
                <Typography variant="h3" gutterBottom>
                  Oops!
                </Typography>
                <Typography variant="body1" flexWrap="wrap">
                  It looks like you recieved an error trying to login . Please
                  contact your organization administrator to verify your account
                  and provide error message below.
                </Typography>
              </Box>
              <Box m={3}>
                <Alert severity="error">{customerPortalError}</Alert>
              </Box>
            </>
          </FormPaper>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }

  return (
    <GlobalContext.Provider
      value={{
        constantsMap,
        getAppConstant,
        organization,
        setCurrentOrg,
        roles,
        addGlobalMessage,
        globalMessages,
        countries,
        taxFilingDeadline,
        beneficiaryClaimStatus,
        beneDistributionOptions,
        taxYears,
        customerPortalUser,
        customerPortalId,
        customerPortalError,
        isCustomerPortalError,
        currentUser,
        irsFieldData,
        activeTaxYear,
        taxFileIndex,
        userList,
        auth0Id
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

// Pagination session key
const PAGINATION_SESSION_KEY = 'datagrid-pagination';

// Separate context so we can use it deeper in the app an prevent rerendering the full app routes
export function PaginationContextProvider({ children }) {
  const [globalPageSize, updateGlobalPageSize] = useState(10 as number);
  const [pageNumber, setPageNumber] = useState(0 as number);
  const pagination = sessionStorage.getItem(PAGINATION_SESSION_KEY);

  useEffect(() => {
    if (pagination) {
      const pageSize = Number(pagination);
      updateGlobalPageSize(pageSize);
    }
  }, [pagination]);

  // Update the current organiation at the global level
  const setGlobalPageSize = (pageSize: any) => {
    updateGlobalPageSize(pageSize.pageSize);
    setPageNumber(pageSize.page);
    sessionStorage.setItem(
      PAGINATION_SESSION_KEY,
      JSON.stringify(pageSize.pageSize)
    );
  };

  return (
    <PaginationContext.Provider
      value={{
        globalPageSize,
        setGlobalPageSize,
        pageNumber,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
}
