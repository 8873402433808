/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, Tooltip } from '@mui/material';
import { Formik, Form, Field } from 'formik';

import StepButtonBar from '../../steps/StepButtonBar';
import {
  OrganizationTaxReportingSettings,
  TaxFileReportingPreference,
} from '../../../api/OrganizationApi.d';

import SiraSwitchField from '../SiraSwitchField';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import SiraSelectField, { SiraSelectItem } from '../SiraSelectField';
import { use } from 'chai';
import SiraRadioField from '../SiraRadioField';
import { on } from 'events';

export const taxSettingsSelection = [
  {
    value: TaxFileReportingPreference.spreadsheet,
    label: 'Spreadsheet (Excel)',
  },
  {
    value: TaxFileReportingPreference.taxFile,
    label: 'Tax File (IRS 750 Format)',
  },
];

export const FINANCIAL_ORG_TAX_REPORTING_SETTINGS_INT: OrganizationTaxReportingSettings =
  {
    TAX_FORM_1099R: false,
    TAX_FORM_1099SA: false,
    TAX_FORM_1099Q: false,
    TAX_FORM_5498: false,
    TAX_FORM_5498SA: false,
    TAX_FORM_5498ESA: false,
    reportingFilePreference: TaxFileReportingPreference.spreadsheet,
    reportingFilePreference1099R: TaxFileReportingPreference.spreadsheet,
    reportingFilePreference1099SA: TaxFileReportingPreference.spreadsheet,
    reportingFilePreference1099Q: TaxFileReportingPreference.spreadsheet,
    reportingFilePreference5498: TaxFileReportingPreference.spreadsheet,
    reportingFilePreference5498SA: TaxFileReportingPreference.spreadsheet,
    reportingFilePreference5498ESA: TaxFileReportingPreference.spreadsheet,
  };

export interface FinancialOrgTaxReportingSettingsFormProps {
  initialValues: OrganizationTaxReportingSettings;
  onSubmit?: Function;
  onReset?: Function;
  onCancel?: Function;
  submitName?: string;
  resetName?: string;
  getYearOptions?: Function;
}

const FinancialOrgTaxReportingSettingsForm = ({
  initialValues,
  onSubmit,
  onReset,
  onCancel,
  submitName,
  resetName,
  getYearOptions,
}: FinancialOrgTaxReportingSettingsFormProps) => {
  const { taxYears } = useGlobalContext();
  const [taxYearDropdown, setTaxYearDropdown] = useState(
    [] as Array<SiraSelectItem>,
  );
  const helpText =
    'Select Spreadsheet if you plan to pull distribution spreadsheets from the Superior platform to review the current data listed in the site and make updates accordingly. Select Tax File if you plan to upload an IRS 750 formatted file to the platform for the 1099 series reporting.';

  useEffect(() => {
    if (taxYears) {
      setTaxYearDropdown(
        taxYears.map((availableYears) => ({
          value: availableYears.taxYear,
          label: availableYears.taxYear.toString(),
        })),
      );
    }
  }, [taxYears]);

  const reatructureData = (data: OrganizationTaxReportingSettings) => {
    // build the data structure to be sent to the API
    const taxReportingSettings = Object.keys(data).map((key, idx) => {
      if (idx < 6) {
        return {
          printableTaxFormType: key,
          isEnabled: data[key],
          reportingFilePreference:
            data[`reportingFilePreference${key.replace('TAX_FORM_', '')}`],
        };
      }
    });

    //there are array entries that are null we delet undefined values
    taxReportingSettings.splice(6,8 );
   onSubmit(taxReportingSettings);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        await reatructureData(values);
      }}
      onReset={() => {
        if (onReset) onReset();
      }}
      enableReinitialize
    >
      {({ isSubmitting, values, setFieldValue }) => {
        const {
          TAX_FORM_1099R,
          TAX_FORM_1099SA,
          TAX_FORM_1099Q,
          TAX_FORM_5498,
          TAX_FORM_5498SA,
          TAX_FORM_5498ESA,
        } = values as OrganizationTaxReportingSettings;

        return (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SiraSelectField
                  name="taxYear"
                  label="Tax Year"
                  items={taxYearDropdown}
                  fullWidth
                  onChange={(e) => {
                    getYearOptions(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                  wrap="nowrap"
                >
                  <Grid item>
                    <Box>
                      <SiraSwitchField
                        name="TAX_FORM_1099R"
                        checked={TAX_FORM_1099R}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography>IRA Distributions (1099-R)</Typography>
                  </Grid>
                </Grid>
                {TAX_FORM_1099R && (
                  <Grid item pl={6}>
                    <Field
                      component={SiraRadioField}
                      name="reportingFilePreference1099R"
                      options={taxSettingsSelection}
                    />
                  </Grid>
                )}
              </Grid>{' '}
              <Grid item xs={12}>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                  wrap="nowrap"
                >
                  <Grid item>
                    <Box>
                      <SiraSwitchField
                        name="TAX_FORM_5498"
                        checked={TAX_FORM_5498}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography>IRA FMV and Deposits (5498)</Typography>
                  </Grid>
                </Grid>
                {TAX_FORM_5498 && (
                  <Grid item pl={6}>
                    <Field
                      component={SiraRadioField}
                      name="reportingFilePreference5498"
                      options={taxSettingsSelection}
                    />
                  </Grid>
                )}
              </Grid>{' '}
              <Grid item xs={12}>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                  wrap="nowrap"
                >
                  <Grid item>
                    <Box>
                      <SiraSwitchField
                        name="TAX_FORM_1099SA"
                        checked={TAX_FORM_1099SA}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography>HSA Distributions (1099-SA)</Typography>
                  </Grid>
                </Grid>
                {TAX_FORM_1099SA && (
                  <Grid item pl={6}>
                    <Field
                      component={SiraRadioField}
                      name="reportingFilePreference1099SA"
                      options={taxSettingsSelection}
                    />
                  </Grid>
                )}
              </Grid>{' '}
              <Grid item xs={12}>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                  wrap="nowrap"
                >
                  <Grid item>
                    <Box>
                      <SiraSwitchField
                        name="TAX_FORM_5498SA"
                        checked={TAX_FORM_5498SA}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography>HSA FMV and Deposits (5498-SA)</Typography>
                  </Grid>
                </Grid>
                {TAX_FORM_5498SA && (
                  <Grid item pl={6}>
                    <Field
                      component={SiraRadioField}
                      name="reportingFilePreference5498SA"
                      options={taxSettingsSelection}
                    />
                  </Grid>
                )}
              </Grid>{' '}
              <Grid item xs={12}>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                  wrap="nowrap"
                >
                  <Grid item>
                    <Box>
                      <SiraSwitchField
                        name="TAX_FORM_1099Q"
                        checked={TAX_FORM_1099Q}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Coverdell ESA Distributions (1099-Q)
                    </Typography>
                  </Grid>
                </Grid>
                {TAX_FORM_1099Q && (
                  <Grid item pl={6}>
                    <Field
                      component={SiraRadioField}
                      name="reportingFilePreference1099Q"
                      options={taxSettingsSelection}
                    />
                  </Grid>
                )}
              </Grid>{' '}
              <Grid item xs={12}>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                  wrap="nowrap"
                >
                  <Grid item>
                    <Box>
                      <SiraSwitchField
                        name="TAX_FORM_5498ESA"
                        checked={TAX_FORM_5498ESA}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography>Coverdell ESA Deposits (5498-ESA)</Typography>
                  </Grid>
                </Grid>

                {TAX_FORM_5498ESA && (
                  <Grid item pl={6}>
                    <Field
                      component={SiraRadioField}
                      name="reportingFilePreference5498ESA"
                      options={taxSettingsSelection}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              resetName={resetName}
              onCancel={onCancel}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default FinancialOrgTaxReportingSettingsForm;
